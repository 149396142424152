<template>
    <div class="d-inline-block" :style="presThumbLinkStyle">
        <img v-if="pres.thumbnail" :src="pres.thumbnail" :style="presThumbImgStyle" alt="Thumbnail" />
    </div>
</template>


<script>

    import MainAppMixin from "../MainAppMixin";
    import PresentationUtils from "../../store/PresentationUtils";

    export default {
        name: 'PresThumbWithChassis',
        mixins: [MainAppMixin],
        props: {
            pres: {type: Object},
            thumbScale: {type: Number}
        },
        computed: {
            presThumbImgStyle: function() {
                let chassisThumb = PresentationUtils.getDeviceChassisImage(this.pres.code);
                if (!chassisThumb) return {};
                return {
                    'width': (this.pres.thumb_width * this.thumbScale) + 'px',
                    'height': (this.pres.thumb_height * this.thumbScale) + 'px',
                    'margin-left': (chassisThumb.thumbOffsetLeft * this.thumbScale) + 'px',
                    'margin-right': (chassisThumb.thumbOffsetLeft * this.thumbScale) + 'px',
                    'margin-top': (chassisThumb.thumbOffsetTop * this.thumbScale) + 'px',
                    'margin-bottom': (chassisThumb.thumbOffsetTop * this.thumbScale) + 'px',
                }
            },
            presThumbLinkStyle: function() {
                let chassisThumb = PresentationUtils.getDeviceChassisImage(this.pres.code);
                if (!chassisThumb) return {};
                let deviceThumbWidth = this.pres.thumb_width + (chassisThumb.thumbOffsetLeft*2);
                let deviceThumbHeight = this.pres.thumb_height + (chassisThumb.thumbOffsetTop*2);
                return {
                    'background-image': 'url(' + this.mainMxCdn(chassisThumb.thumbPath) + ')',
                    'background-repeat': 'no-repeat',
                    'background-size':
                        (deviceThumbWidth * this.thumbScale) + 'px ' +
                        (deviceThumbHeight * this.thumbScale) + 'px',
                    'width': (deviceThumbWidth * this.thumbScale) + 'px',
                    'height': (deviceThumbHeight * this.thumbScale) + 'px'
                }
            },
        }
    }


</script>

