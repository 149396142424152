<template>
    <div>
        <div class="mb-3">
            <h3 class="d-inline">Import</h3>
            <span v-if="!canImport" class="ms-3">
                <span v-if="uploadCompleteInt > 0 && !creatingShowcase" class="text-muted">
                    <ScIcon name="spinnerFW" class="me-1"/> {{uploadCompleteInt}}%</span>
                <span v-if="creatingShowcase" class="text-muted">
                    <ScIcon name="spinnerFW" class="me-1"/> Creating presentation...</span>
            </span>
        </div>

        <div class="d-flex mb-4">
            <div class="mx-4"><button class="btn btn-light pres-import-template-preview px-5 py-3"
                                      @click.prevent="importNow('.showcase')" :disabled="!canImport">
                <img src="https://cdn.showcaseworkshop.com/showcase-icons/7.1.1/200x200_scw.png"
                     style="width: 50px;height:50px;" class="mb-2"/>
                <h4 class="mb-0">Showcase</h4>
                <small>.showcase</small>
            </button></div>
            <div class="mx-4"><button class="btn btn-light pres-import-template-preview px-5 py-3"
                                      @click.prevent="importNow('.pdf')" :disabled="!canImport">
                <img src="https://cdn.showcaseworkshop.com/showcase-icons/7.1.1/200x200_pdf.png"
                     style="width: 50px;height:50px;" class="mb-2"/>
                <h4 class="mb-0">PDF</h4>
                <small>.pdf</small>
            </button></div>
            <div class="mx-4"><button class="btn btn-light pres-import-template-preview px-5 py-3"
                                      @click.prevent="importNow('.ppt,.pptx,.odp')" :disabled="!canImport">
                <img src="https://cdn.showcaseworkshop.com/showcase-icons/7.1.1/200x200_ppt.png"
                     style="width: 50px;height:50px;" class="mb-2"/>
                <h4 class="mb-0">PowerPoint</h4>
                <small>.ppt .pptx .odp</small>
            </button></div>
            <div class="mx-4"><button class="btn btn-light pres-import-template-preview px-5 py-3"
                                      @click.prevent="importNow('.docx,.doc,.odt')" :disabled="!canImport">
                <img src="https://cdn.showcaseworkshop.com/showcase-icons/7.1.1/200x200_doc.png"
                     style="width: 50px;height:50px;" class="mb-2"/>
                <h4 class="mb-0">Document</h4>
                <small>.docx .doc .odt</small>
            </button></div>
        </div>
        <div class="ms-2">
            <small class="text-muted">
                <em>.showcase</em> files must have been exported within the last 7 days.
                <br/>All other files have a 100MB size limit; links and animations will not be maintained; first 100
                slides or pages only.
                <br/>For PowerPoint and Word, use system default fonts or embed fonts to avoid errors.
            </small>
        </div>

        <LibraryUploader ref="presFileUploader"
            :options="libraryUploaderOptions" libStorePrefix="libModal"
            @started="fileUploadStart"
            @success="fileUploadDone" ></LibraryUploader>
    </div>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import LibraryUploader from '../library/LibraryUploader.vue';
    import MainAppMixin from "../MainAppMixin";


    export default {
        name: 'NewFromImport',
        emits: ['pres-created'],
        mixins: [MainAppMixin],
        components: {LibraryUploader, ScIcon},
        data: function() {
            return {
                presId: null,
                creatingShowcase: false,
                uploadingFileGuid: null,
                libraryUploaderOptions: {
                    maxFileSizeBytes: 1024 * 1024 * 100, // 100mb
                    validExtensions: ['showcase', 'pdf', 'doc', 'docx', 'odt', 'ppt', 'pptx', 'odp']
                }
            }
        },
        methods: {

            importNow(acceptsFileExts) {
                this.$refs.presFileUploader.triggerChooseFile(acceptsFileExts);
            },
            fileUploadStart: function(uploadingFileGuid) {
                //console.log('start', uploadingFileGuid);
                this.uploadingFileGuid = uploadingFileGuid;
            },

            fileUploadDone: function(uploadedFile) {
                //console.log('done', uploadedFile);
                let lcFileName = uploadedFile.name.toLowerCase();
                this.creatingShowcase = true;
                if (lcFileName.indexOf('.showcase', lcFileName.length - '.showcase'.length) !== -1) {
                    $.ajax({
                        type: 'POST', url: '/main/new_presentation/ajax_import_pres',
                        data: {workspace_id: this.mainMxCurrentWorkshopId, upload_key: uploadedFile.upload_key}
                    }).done((data) => {
                        this.presImportStarted(data);

                    }).always(() => {
                        this.creatingShowcase = false;

                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'triggering import');
                    });

                } else {
                    //ScNotification.growlErrMsg('File uploading... Please wait...');
                    $.ajax({
                        type: 'POST', url: '/main/new_presentation/ajax_document_to_new_showcase',
                        data: {workspace_id: this.mainMxCurrentWorkshopId, upload_key: uploadedFile.upload_key,
                            upload_name: uploadedFile.name}
                    }).done((data) => {
                        if (data.error) {
                            ScNotification.growlErrMsg(data.errormessage);
                        } else {
                            this.presImportStarted(data);
                        }

                    }).always(() => {
                        this.creatingShowcase = false;

                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'uploading');
                    });
                }
            },
            presImportStarted: function(data) {
                this.creatingShowcase = false;
                this.uploadingFileGuid = null;
                ScNotification.growlSuccessMsg('Import process started. This may take up to 10 minutes.');
                this.$emit('pres-created', data);
            },
        },
        computed: {
            canImport() {
                return !this.uploadingFile && !this.creatingShowcase;
            },
            uploadCompleteInt: function() {
                if (this.uploadingFile) return this.uploadingFile.upload_progress_int;
                return 0;
            },
            uploadingFile: function() {
                if (!this.uploadingFileGuid) return null;
                return this.$store.getters['libModal/libGetUploadById'](this.uploadingFileGuid);
            }
        },

    };

</script>

<style scoped>

.btn.pres-import-template-preview:hover {
    border: 1px solid #c0c0c0;
}

</style>
