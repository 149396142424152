<template>
    <ScNormalPageLayout :headingPart1="headingPart1" :headingPart2="headingPart2">
        <div class="container">
            <div class="row mt-4">
                <div class="col">

                    <div v-if="userPrefs.currentWorkshopId" class="clearfix">

                        <LabelSearchSortBar
                                :loadingPresentations="$store.state.plist.presentationsLoading"
                                :manageLabelsEnabled="true"
                                :labels="labelsWithPresIds"
                                :selectedLabelIds="selectedLabelIds"
                                :presListOrder="userPrefs.home_order_by"
                                :canFilter="true" :presListFilter="$store.state.plist.plistPresListFilter"
                                @open-manage-all-labels-modal="openManageAllLabelsModal"
                                @toggle-label="toggleLabel"
                                @set-sort-type="setSortType"
                                @set-pres-list-filter="setPresListFilter"
                                @update-search-val="updateSearchVal"
                                @refresh-showcases-list="refreshShowcases"/>

                        <div v-if="$store.state.plist.presentationsLoading" class="m-2">
                            <div class="text-center" style="margin-top: 20vh; margin-bottom: 20vh;">
                                <ScIcon name="spinnerMedium" class="text-muted"/>
                            </div>
                        </div>

                        <transition name="sc-transition-fade">
                            <WorkshopHomeNewPres v-if="showNewPresLink"></WorkshopHomeNewPres>
                        </transition>

                        <div v-if="!$store.state.plist.presentationsLoading && $store.state.plist.presentations && sortedPresentations.length === 0"
                             style="min-height: 100vh;">
                            <div class="col-md-5 text-center mt-5 ms-auto me-auto">
                                <em class="text-muted">No presentations</em>
                                <p class="mt-4 text-muted" v-if="$store.state.plist.presentations.length > 0">
                                    <ScIcon name="questionCircle" class="text-muted"/>
                                    Try simplifying the search text, changing<br/>
                                    the filter, or deselecting labels.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- todo: transition disabled for now (june 2021) as it's too slow with 300 items
        <transition-group v-if="!$store.state.plist.presentationsLoading" name="sc-transition-basic-list"-->
<!--                          tag="div" style="min-height: 100vh;" class="mb-5">-->
        <div v-if="!$store.state.plist.presentationsLoading" style="min-height: 100vh;" :class="['mb-5', !showNewPresLink ? 'mt-4':'']">
            <WorkshopHomePresentation
                    v-for="pres in sortedPresentations"
                    :key="'p'+pres.id" :ref="'workshopHomePres'+pres.id"
                    :pres="pres"></WorkshopHomePresentation>
        </div>
<!--        </transition-group>-->

        <ManageAllLabelsModal :workspaceId="mainMxCurrentWorkshopId"></ManageAllLabelsModal>
        <AssignLabelsModal></AssignLabelsModal>
        <ScColorPickerModal></ScColorPickerModal>

        <ManageAccessModal></ManageAccessModal>
        <PresentationCopy></PresentationCopy>
        <PresentationExport></PresentationExport>
        <PresentationShareable></PresentationShareable>
        <PresentationDelete></PresentationDelete>
        <PresentationHistory></PresentationHistory>
        <PresentationPublish></PresentationPublish>
        <PresentationChangeStatus></PresentationChangeStatus>
        <PresentationFileUsages></PresentationFileUsages>

        <!-- for sub routes -->
        <router-view></router-view>
    </ScNormalPageLayout>
</template>

<script>

import ScNormalPageLayout from "./global/ScNormalPageLayout.vue";
import WorkshopHomePresentation from "./WorkshopHomePresentation.vue";
import WorkshopHomeNewPres from "./new-presentation/WorkshopHomeNewPres.vue";
import BrowserViewerEmbedHandler from "./browser-viewer/BrowserViewerEmbedHandler";
import MainAppMixin from "./MainAppMixin";
import AssignLabelsModal from "./presentation/labels/AssignLabelsModal.vue";
import ManageAccessModal from "./presentation/manage-access/ManageAccessModal.vue";
import ManageAllLabelsModal from "./presentation/labels/ManageAllLabelsModal.vue";
import ScIcon from "../shared/common/ScIcon.vue";

import store from "../store";
import PresentationListStore from "../store/PresentationListStore";
import LabelSearchSortBar from "./presentation/labels/LabelSearchSortBar.vue";
import PresentationCopy from "./presentation/PresentationCopy.vue";
import PresentationExport from "./presentation/PresentationExport.vue";
import PresentationShareable from "./presentation/PresentationShareable.vue";
import PresentationDelete from "./presentation/PresentationDelete.vue";
import PresentationHistory from "./presentation/PresentationHistory.vue";
import PresentationPublish from "./presentation/PresentationPublish.vue";
import PresentationChangeStatus from "./presentation/PresentationChangeStatus.vue";
import PresentationFileUsages from "./presentation/PresentationFileUsages.vue";
import ScColorPickerModal from "./global/ScColorPickerModal.vue";

if (!store.state.plist) store.registerModule('plist', PresentationListStore);

export default {
    name: 'WorkshopHome',
    mixins: [MainAppMixin],
    components: { ScColorPickerModal, ScNormalPageLayout, WorkshopHomePresentation, WorkshopHomeNewPres, ScIcon,
        LabelSearchSortBar, ManageAllLabelsModal, AssignLabelsModal, ManageAccessModal,
        PresentationCopy, PresentationExport, PresentationShareable, PresentationDelete, PresentationHistory,
        PresentationPublish, PresentationChangeStatus, PresentationFileUsages },
    data () {
        return {
            searchVal: null,
        }
    },
    mounted () {
        //console.log('WorkshopHome mounted');
        this.workshopIdChanged();
        // listen to for events from ScOperationsV2
        document.addEventListener('sc_event:presentation_delete', this.windowPresentationDelete);
        document.addEventListener('sc_event:presentation_import_progress', this.windowPresentationImportProgress);
        document.addEventListener('sc_event:page_thumbnail_ready', this.windowPresentationImportPageDone);
        document.addEventListener('sc_event:presentation_import_complete', this.windowPresentationImportComplete);
        document.addEventListener('sc_event:workshop_home_show_pres', this.windowWorkshopHomeShowPres);
    },
    beforeUnmount () {
        document.removeEventListener('sc_event:presentation_delete', this.windowPresentationDelete);
        document.removeEventListener('sc_event:presentation_import_progress', this.windowPresentationImportProgress);
        document.removeEventListener('sc_event:page_thumbnail_ready', this.windowPresentationImportPageDone);
        document.removeEventListener('sc_event:presentation_import_complete', this.windowPresentationImportComplete);
        document.removeEventListener('sc_event:workshop_home_show_pres', this.windowWorkshopHomeShowPres);
    },
    methods: {
        refreshShowcases () {
          if (!this.$store.state.user.user_id || !this.mainMxCurrentWorkshopId) return;  // in process of logging out
          if (this.$store.getters.userCurrentWorkspaceObj.is_trial_plan) this.$store.commit('userShowGuide', true); // open banner if on a trial
          this.$store.dispatch('plistLabelsLoad', {workspaceId: this.mainMxCurrentWorkshopId});
          this.$store.dispatch('plistPresentationsLoad', {workspaceId: this.mainMxCurrentWorkshopId});
          BrowserViewerEmbedHandler.recordScAnalyticsEvent('workshop_view', null, this.mainMxCurrentWorkshopId,
              this.$store.state.user.user_id);
        },
        workshopIdChanged () {
            this.refreshShowcases();
            // reset filter, search and labels
            this.searchVal = null;
            this.$store.commit('pListLabelReset');
        },
        windowPresentationDelete (e) {
            this.$store.commit('plistPresDeleted', e.detail.presentation_id);
        },
        windowPresentationImportProgress (e) {
            this.$store.commit('plistPresImportProgress', e.detail);
        },
        windowPresentationImportPageDone (e) {
            this.$store.commit('plistPresImportPageDone', e.detail);
        },
        windowPresentationImportComplete (e) {
            this.$store.commit('plistPresImportComplete', e.detail);
        },
        windowWorkshopHomeShowPres (e) {
            let presComp = this.$refs['workshopHomePres' + e.detail.presentation_id];
            let el = presComp && presComp.$el ? presComp.$el : undefined;
            if (el && typeof el.scrollIntoView === 'function') {
              el.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        },
        setSortType (type) {
            this.$store.dispatch('userSaveHomePrefs', {sortOrder: type})
        },
        toggleLabel (labelId) {
            this.$store.commit('pListLabelToggleSelected', labelId);
        },
        setPresListFilter(presListFilter) {
          this.$store.commit('pListSetPresListFilter', presListFilter);
        },
        openManageAllLabelsModal () {
            ManageAllLabelsModal.modal();
        },
        updateSearchVal (value) {
            this.searchVal = value;
        }
    },
    computed: {
        labelsWithPresIds() {
            if (!this.labels) return [];
            let allPresIds = this.$store.state.plist.presentations.map(pres => pres.id);
            return this.labels.filter((label) => {
                return label.presentation_ids.length > 0 && label.presentation_ids.some(pId => allPresIds.includes(pId));
            });
        },
        headingPart1() {
            let headingPart1 = 'Workshop';
            if (this.mainMxCurrentWorkshop && this.mainMxCurrentWorkshop.name) {
                let wsName = this.mainMxCurrentWorkshop.name;
                let wsNameLc = wsName.toLocaleLowerCase();
                let wsSuffix = ' workshop';
                if (wsNameLc && wsNameLc.endsWith && wsNameLc.endsWith(wsSuffix)) {
                    wsName = this.mainMxCurrentWorkshop.name.substring(0, wsName.length - wsSuffix.length);
                }
                headingPart1 = wsName;
            }
            return headingPart1
        },
        headingPart2() {
            if (this.mainMxCurrentWorkshop && this.mainMxCurrentWorkshop.name) return 'Workshop';
            return 'Home';
        },
        userPrefs() {
            return this.$store.state.user;
        },
        labels () {
            return this.$store.state.plist.labels;
        },
        selectedLabelIds () {
            return this.$store.state.plist.selectedLabelIds;
        },
        searchedByFilter() {
            let filterConfigObj = {
              'show-default': {statuses: ['importing', 'unpublished', 'published'], templateBools: [false]},
              'show-importing': {statuses: ['importing'], templateBools: [false]},
              'show-unpublished': {statuses: ['unpublished'], templateBools: [false]},
              'show-published': {statuses: ['published'], templateBools: [false]},
              'show-archived': {statuses: ['archived'], templateBools: [true, false]},
              'show-templates': {statuses: ['published','unpublished'], templateBools: [true]}
            };
            let filterConfig = filterConfigObj[this.$store.state.plist.plistPresListFilter];
            return this.$store.state.plist.presentations.filter((pres) => {
              return filterConfig && filterConfig.statuses.indexOf(pres.publish_status) !== -1
                && filterConfig.templateBools.indexOf(pres.template) !== -1;
            })
        },
        searchedByText () {
            //console.log('searchedPresentationsByText', this.searchVal);
            return this.searchedByFilter.filter((p) => {
                return !this.searchVal
                    || p.title.toLocaleLowerCase().indexOf(this.searchVal.toLocaleLowerCase()) > -1;
            });
        },
        searchedByLabels () {
            return this.searchedByText.filter((pres) => {
              if (this.selectedLabelIds.length === 0) return true;
              let numLabelsWithPresId = 0;
              this.labels.forEach((label) => {
                if (this.selectedLabelIds.indexOf(label.id) === -1) return;
                if (label.presentation_ids.indexOf(pres.id) !== -1) {
                  numLabelsWithPresId++;
                }
              });
              return numLabelsWithPresId === this.selectedLabelIds.length;
            });
        },
        sortedPresentations () {
            //console.log('sortedPresentations333', this.sortType);
            return this.searchedByLabels.slice(0).sort((a, b) => {
                if (this.userPrefs.home_order_by === 'name_asc' && a.title && b.title) {
                    return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
                } else if (this.userPrefs.home_order_by === 'id_desc') {
                    return a.id - b.id;
                } else {
                    return b.id - a.id;
                }
            });
        },
        showNewPresLink() {
          return !this.$store.state.plist.presentationsLoading && this.selectedLabelIds.length === 0
              && !this.searchVal && this.$store.state.plist.plistPresListFilter === 'show-default';
        }
    },
    watch: {
        mainMxCurrentWorkshopId () {
            this.workshopIdChanged();
        }
    }
}

</script>
