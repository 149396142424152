<template>
    <div class="row pres-list-new-template border-bottom pb-3">
        <div class="col-auto text-center" style="width: 275px;">
              <a href="#" v-sc-trackevent="'list:new_from_template_plus'" class="btn btn-link"
                    @click.prevent="$router.push({name: 'new-presentation'})">
                  <div class="tmpl-img-placeholder m-1 d-flex justify-content-center mx-auto border rounded bg-white"
                        style="height: 90px;width: 180px;">
                      <ScIcon name="plusMedium" class="my-auto"/>
                  </div>
              </a>
          </div>

          <div class="col my-auto ps-3">
              <h4><router-link :to="{name: 'new-presentation'}">
                  New Presentation</router-link></h4>
          </div>
    </div>
</template>

<script>
    import MainAppMixin from "../MainAppMixin";
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: "WorkshopNewTemplate",
        mixins: [ MainAppMixin ],
        components: {ScIcon},
        methods: {
          //
        }
    }
</script>

