<template>
    <ScNormalPageLayout :headingPart1="presentationTitle" headingPart2="Email Users">
        <div class="container my-5">
            <div v-if="!isSent" class="row justify-content-center">
                <div class="col-9 my-4">
                    <div class="card">
                    <div v-if="loadingConfig" class="card-body">
                        <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>
                    <div v-else class="card-body">

                        <span v-if="presentationThumbnail" class="float-start me-3">
                            <img :src="presentationThumbnail" :alt="presentationTitle" style="width:150px" />
                        </span>
                        <button type="button" class="btn btn-link text-muted float-end"
                                v-sc-tooltip="'Export list of recipients as CSV'"
                                :disabled="loadingAllRecipients" @click.prevent="loadAllRecipients">
                            <ScIcon v-if="loadingAllRecipients" name="spinnerFW" class="mx-3"/>
                            <span v-else><ScIcon name="download"/> CSV</span>
                        </button>

                        <h3>{{ presentationTitle }}</h3>

                        <div>All <strong>{{ formatCountWording(count, 'user') }}</strong>
                            with access to this presentation will receive your email.</div>

                    </div>
                    </div>
                </div>

                <form id="email-viewers-form" action="#" method="post" class="col-9">

                    <div class="mb-4">
                        <label for="email-subject">Subject</label>
                        <input type="text" id="email-subject" class="form-control"
                               :disabled="loadingConfig || loadingSave"
                               autocomplete="subject" placeholder="Email subject"
                               maxlength="255" :value="subject"
                               @change="updateSubject"/>
                    </div>

                    <div class="mb-4">
                        <label for="message">Message</label>
                        <textarea class="form-control" id="message" placeholder="Type your message..."
                            @change="loadPreview" :disabled="loadingConfig || loadingSave"
                            v-model="message"></textarea>
                        <small class="text-muted float-end my-2"><ScIcon name="questionCircle"/> You can use <a
                            href="https://support.showcaseworkshop.com/help/how-to-use-markdown"
                            @click.prevent="openExternally('https://support.showcaseworkshop.com/help/how-to-use-markdown')">markdown</a> in the message
                        </small>
                    </div>

                    <div class="mt-5 mb-0">
                        <label class="control-label">Preview</label>
                    </div>

                    <div class="mb-4">
                        <iframe id="iframe-preview" class="shadow-lg me-auto ms-auto w-100 border-0 rounded bg-white"
                                style="height: 400px;"></iframe>
                    </div>


                    <div class="text-center mb-5">
                        <button type="button" class="btn btn-primary-orange fw-bold"
                                @click.prevent="submitForm"
                                :disabled="(loadingSave || loadingConfig || count === 0)">
                            <ScIcon v-if="loadingSave" name="spinnerFW" class="mx-3 text-white"/>
                            <span v-else>Send</span>
                        </button>
                    </div>

                </form>
            </div>
            <div v-else class="row justify-content-center">
                <div class="col-9 my-3">
                    <div class="alert alert-success"><strong>Great!</strong> Your email to these users has been queued and will be sent during the next 15 minutes.</div>
                </div>
                <div class="col-9 text-center">
                    <router-link :to="{ name: 'workshop-home', params: { workshopId: mainMxCurrentWorkshopId }}"
                                 class="btn btn-outline-secondary">Back to Home</router-link>
                </div>
            </div>
        </div>
    </ScNormalPageLayout>
</template>

<script>
    import $ from 'jquery';
    import {format} from 'date-fns';
    import Papa from 'papaparse';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import MainAppMixin from '../MainAppMixin';
    import ScCsvUtil from "../global/ScCsvUtil";

    let _defaultSubject = function(presTitle) {
        return 'Here\'s a message about ' + presTitle;
    }

    let _defaultMessage = function(userDisplayName, email, presTitle) {
        return `Hi,

Here's a message about ${presTitle}.

Please email **${userDisplayName}** if you would like more information:
**[${email}](${email})**
`;
    }

    export default {
        name: "EmailViewers",
        mixins: [MainAppMixin],
        components: { ScNormalPageLayout, ScIcon},
        data () {
            return {
                presentationId: 0,
                presentationThumbnail: '',
                presentationTitle: '',
                count: 0,
                message: null,
                subject: null,
                isSent: false,
                loadingConfig: false,
                loadingSave: false,
                loadingAllRecipients: false
            };
        },
        mounted () {
            window.scrollTo({ top: 0, left: 0 });
            this.presentationId = Number(this.$route.params.showcaseId);
            this.loadDefault();
        },
        methods: {
            formatCountWording (num, word) {
                return (num > 1) ? num + " " + word + "s" : num + " " + word;
            },
            updateSubject (val) {
                this.subject = val.currentTarget.value.trim();
            },
            submitForm () {
                if (!this.message) {
                    ScNotification.growlErrMsg('Please enter a message');
                } else if (!this.subject) {
                    ScNotification.growlErrMsg('Please enter a subject');
                } else {
                    this.saveData();
                }
            },
            openExternally(url) {
                window.open(url, '_blank');
            },


            loadPreview () {
                $.ajax({
                    type: "POST", url: "/main/presentations/email_viewers_preview",
                    data: {workspace_id: this.mainMxCurrentWorkshopId, message: this.message}
                }).done((data) => {
                    $('#iframe-preview').contents().find('html').html(data);
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "previewing email");
                });
            },
            saveData () {
                this.loadingSave = true;
                $.ajax({
                    type: "POST",
                    url: "/main/presentations/ajax_save_email_viewers_v2",
                    data: {
                        workspace_id: this.mainMxCurrentWorkshopId,
                        presentation_id: this.presentationId,
                        message: this.message,
                        subject: this.subject
                    }
                }).done(() => {
                    this.isSent = true;
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    this.message = _defaultMessage(this.$store.state.user.displayable_name, this.$store.state.user.email,
                        this.presentationTitle);
                    this.subject = _defaultSubject(this.presentationTitle);
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "sending email");
                }).always(() => {
                    this.loadingSave = false;
                });
            },
            loadDefault () {
                this.loadingConfig = true;
                $.ajax({
                    type: "GET",
                    url: "/main/presentations/ajax_get_email_viewers_config_v2",
                    data: {
                        workspace_id: this.mainMxCurrentWorkshopId,
                        presentation_id: this.presentationId,
                    }
                }).done((data) => {
                    this.presentationId = data.presentation_id;
                    this.presentationThumbnail = data.presentation_thumbnail.split('&amp;').join('&');
                    this.presentationTitle = data.presentation_title;
                    this.message = _defaultMessage(this.$store.state.user.displayable_name,
                      this.$store.state.user.email, data.presentation_title);
                    this.subject = _defaultSubject(data.presentation_title);
                    this.count = data.count;
                    this.loadPreview();
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "getting recipient details");
                }).always(() => {
                    this.loadingConfig = false;
                });
            },
            loadAllRecipients() {
              this.loadingAllRecipients = true;
              $.ajax({
                type: "POST", url: "/main/presentations/ajax_email_viewers_recipients",
                data: {workspace_id: this.mainMxCurrentWorkshopId, presentation_id: this.presentationId}
              }).done((data) => {
                this.loadingAllRecipients = false;
                this.formatToCsv(data.recipients);
              }).fail((jqXhr) => {
                this.loadingAllRecipients = false;
                ScNotification.growlXhrError(jqXhr, "getting recipient list");
              });
            },
            formatToCsv(linesArray) {
                let fileName = `users-with-access-${format(new Date(), 'yyyyMMddHHmm')}.csv`;
                let csvData = [['Email','Name','User Workshop Reference']];
                linesArray.forEach(function (modLine) {
                  csvData.push([modLine.email, modLine.name, modLine.user_ws_ref]);
                });
                let finalCsvText = Papa.unparse(csvData);
                ScCsvUtil.deliverCsv(finalCsvText, fileName);
            }
        }

    }
</script>

<style scoped>

    #email-viewers-form textarea { width: 100%; height: 150px; }

</style>