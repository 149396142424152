<template>
    <ScNormalPageLayout headingPart1="New" headingPart2="Presentation">
        <div class="container my-5">
            <div class="h4 fw-normal">
                <router-link :to="{ name: 'workshop-home', params:{ workshopId: mainMxCurrentWorkshopId }}">
                    <ScIcon name="angleLeft" class="me-2"/> Back
                </router-link>
            </div>

            <div v-if="isCreatingPres">

                <ScIcon name="spinnerFW" class="text-muted"/> Creating...

            </div>
            <div v-else>
                <NewFromWorkshopTemplate @create-pres="createPres($event, null, null)"></NewFromWorkshopTemplate>

                <NewFromSystemTemplate @create-pres="createPres(null, $event, null)"></NewFromSystemTemplate>

                <NewFromBlank @create-pres="createPres(null, null, $event)"></NewFromBlank>

                <NewFromImport @pres-created="presCreated"></NewFromImport>
            </div>

        </div>
    </ScNormalPageLayout>
</template>

<script>

import $ from 'jquery';
import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
import MainAppMixin from '../MainAppMixin';
import NewFromImport from './NewFromImport.vue';
import NewFromBlank from './NewFromBlank.vue';
import NewFromSystemTemplate from './NewFromSystemTemplate.vue';
import ScIcon from '../../shared/common/ScIcon.vue';
import NewFromWorkshopTemplate from "./NewFromWorkshopTemplate.vue";
import ScNotification from "../../shared/common/ScNotification.vue";

export default {
  name: "NewPresentation",
  mixins: [MainAppMixin],
  components: {
    NewFromWorkshopTemplate,
    ScNormalPageLayout, NewFromImport, NewFromBlank, NewFromSystemTemplate,
    ScIcon
  },
  data () {
    return {
      isCreatingPres: false
    };
  },
  mounted () {
    //
  },
  methods: {
    createPres(workshopTemplateId, templateUuid, blankTemplateCode) {
      this.isCreatingPres = true;
      $.ajax({
        method: 'POST', url: '/main/new_presentation/ajax_new_showcase',
        data: { workshop_template_id: workshopTemplateId, template_uuid: templateUuid, layout_code: blankTemplateCode,
                workspace_id: this.mainMxCurrentWorkshopId}
      }).done((data) => {
        this.presCreated(data);
      }).fail((jqXhr) => {
        this.isCreatingPres = false;
        ScNotification.growlXhrError(jqXhr, 'creating');
      });
    },
    presCreated(data) {
      this.isCreatingPres = true;
      if (this.$store && this.$store.state && this.$store.state.plist) {
        this.$store.commit('plistPresAdded', data);
      }
      this.$router.push({
        name: 'workshop-home', params: {workshopId: this.mainMxCurrentWorkshopId}, hash: '#p' + data.presentation_id
      }).then(() => {
        setTimeout(() => {  // i wish there was a better way to do this
          document.dispatchEvent(new CustomEvent('sc_event:workshop_home_show_pres', {detail: data}));
        }, 2000);
      });
    },
  }

}
</script>

<style scoped>


</style>