<template>
  <div>
    <div v-for="hotspot in getHotspots(slideDetails)" :key="hotspot.id">
      <template v-if="hotspot.target_resource_id">
        <span v-if="hotspot.target_res.content_type === 'weburl' && hotspot.target_res.name && hotspot.target_res.name.indexOf('showcaseform:') === 0">
          <ScIcon name="listFW" v-sc-tooltip="'Showcase Form'"/>&nbsp;
          <span v-sc-tooltip="hotspot.target_res.name">Showcase Form</span>
        </span>
        <span v-else>
          <span v-sc-tooltip="formatHotspotContentType(hotspot.target_res)">
            <ScIcon :name="hotspotIconName(hotspot.target_res)"/>
            {{ hotspot.target_res.name }}
          </span>
        </span>
        <span v-if="hotspot.target_res.content_type !== 'weburl'">
          &nbsp;<span class="text-muted">{{ formatToFileSizeWithBytes(getResByteSize(hotspot.target_res)) }}</span>
          <a href="#" v-sc-trackevent="'outline:resource_info_popover'" class="hidden-print resource-info-popover-link"
             @click.prevent="mainMxShowResInfo(hotspot.target_res.id)"><ScIcon name="infoCircle" class="ms-1"/></a>
        </span>
        <br/>
      </template>
      <template v-else-if="hotspot.target_page_id && hotspot.target_page">
        <span>
          <a :href="formatForwardLink(hotspot.target_page_id)"
             @click.prevent="showDetailView(hotspot.target_page_id)">
            <ScIcon name="squareFW"/>&nbsp;{{ hotspot.target_page.alpha_num_name }}
          </a>
        </span>
        <br/>
      </template>
    </div>
  </div>
</template>

<script>
    import MainAppMixin from '../../MainAppMixin';
    import constants from '../../../constants';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import ScCommonUtil from '../../../shared/common/ScCommonUtil';
    import OutlineUtil from "./OutlineUtil";

    export default {
      name: 'OutlineHotspots',
      emits: ['show-detail-view'],
      mixins: [ MainAppMixin ],
      components: {ScIcon},
      props: {
        slideDetails: {type: Object, default: null},
        scData: {type: Object, default: null}
      },
      methods: {
        getHotspots (page) {
          let hotspots = page.linked_hotspots;

          for (let spot of hotspots) {
            let target_res = this.scData.resource[spot.target_resource_id];
            let target_page = this.scData.page[spot.target_page_id];
            spot["target_res"] = target_res;
            spot["target_page"] = target_page;
          }

          return this.sortHotspots(hotspots);
        },
        sortHotspots (hotspots) {
          let hsPosInt = function(hs) {
            let posInt = 0;
            if (hs.bounds && hs.bounds.indexOf(',') > -1) {
              let boundsArr = hs.bounds.split(',');
              if (boundsArr.length === 4) {
                let x = Number(boundsArr[0]);
                let y = Number(boundsArr[1]);
                posInt = x + y;
              }
            }
            return posInt;
          };
          return hotspots.sort((hs1, hs2) => {
            return hsPosInt(hs1) - hsPosInt(hs2);
          });
        },
        hotspotIconName (hotspot) {
          return constants.RES_CONTENT_TYPE_TO_ICON[hotspot.content_type];
        },
        formatHotspotContentType (hotspot) {
          return constants.RES_CONTENT_TYPE_TO_NAME[hotspot.content_type];
        },
        showDetailView (pageId) {
          this.$emit('show-detail-view', pageId);
        },
        formatForwardLink (pageId) {
          return (pageId) ? `#slide-id/${pageId}` : '';
        },
        getResByteSize (item) {
          return OutlineUtil.getResByteSize(item, this.scData);
        },
        formatToFileSizeWithBytes (val) {
          return (val > 0) ? ScCommonUtil.filesizeWithBytes(val) : 0;
        }
      }
    }
</script>
