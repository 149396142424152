<template>
    <div class="pres-item-full-width border-bottom py-2">
        <div class="container">
            <div class="row">
                <div class="col-auto text-center my-auto" style="width: 275px;">
                    <router-link :to="{ name: 'editor', params: {
                        workshopId: mainMxCurrentWorkshopId, showcaseId: pres.id }}"
                        v-if="presIsEditable" :title="'Edit ' + pres.title">
                        <PresThumbWithChassis :pres="pres" :thumbScale="0.75"></PresThumbWithChassis>
                    </router-link>
                    <router-link v-else-if="pres.publish_status !== 'importing'"
                         :to="{name: 'workshop-home-preview', params: {
                            workshopId: this.mainMxCurrentWorkshopId, showcaseId: this.pres.id}}"
                         :title="'Preview ' + pres.title ">
                        <PresThumbWithChassis :pres="pres" :thumbScale="0.75"></PresThumbWithChassis>
                    </router-link>
                </div>
                <div class="col my-auto ps-0">
                    <div v-if="pres.publish_status === 'importing'" class="ps-3 my-3">
                        <h4 class="generating">{{pres.title}}</h4>
                        <div>
                            <ScProgressCircle :value="((pres.tasks_done/pres.total_tasks)*100)"
                                              v-if="pres.total_tasks > 0" ></ScProgressCircle>
                            Generating, please hold tight!

                            &nbsp; &nbsp; <a v-if="!pres.is_read_only" href="#"
                                             @click.prevent="cancelImport(pres.id)">Cancel</a>
                            <br/><br/>
                        </div>

                    </div>
                    <div v-else class="my-3">

                        <div class="float-end text-end" style="max-width: 350px;">
                            <a href="#" @click.prevent="showManageLabels()"
                               v-sc-tooltip="'Assign Labels'" class="icon manage-labels">
                                <ScIcon name="tag" class="h5 mb-0 align-bottom text-muted"/></a>&nbsp;
                            <div v-for="presLabel in presLabels" :key="'p' + pres.id + '-l' + presLabel.id"
                                 class="badge me-1 fw-bold" :style="{'background-color': presLabel.bg_color }">
                                {{ presLabel.name }}</div>
                        </div>

                        <h4 class="ps-3">
                            <router-link v-if="presIsEditable" :to="{ name: 'editor', params: {
                                    workshopId: mainMxCurrentWorkshopId, showcaseId: pres.id }}">{{pres.title}}</router-link>
                            <router-link v-else :to="{name: 'workshop-home-preview', params: {
                                    workshopId: this.mainMxCurrentWorkshopId, showcaseId: this.pres.id}}"
                                     title="Preview">{{ pres.title }}</router-link>

                            <small><span v-if="pres.template"
                                  v-sc-tooltip="'Not visible to Viewers. Editors with access and Admins can use for new presentations.'"
                                  class="badge rounded-pill bg-light ms-2 py-1 px-2">Template</span></small>

                            <small v-if="pres.is_read_only && pres.owner_workshop_name" class="ps-3 pt-0 text-muted">
                                (Owned by {{ pres.owner_workshop_name }})
                            </small>
                        </h4>

                        <div v-if="pres.publish_status==='archived'" class="ps-3 pt-3 status publish-control">
                            Archived
                        </div>
                        <div v-else-if="pres.is_read_only" class="ps-3 pt-3 status publish-control">
                            <div class="published">
                                <ScIcon name="cloudCheckLayeredHome" class="me-1 align-middle text-success"/>
                                &nbsp;<span v-if="pres.owner_workshop_name">
                                    Version {{ pres.version }} published
                                </span><span v-else>
                                    Published
                                </span>
                            </div>
                        </div>
                        <div v-else class="status publish-control mt-4 ps-3">

                            <div v-if="( pres.publish_status==='published' && !pres.has_changes )" class="published">
                                <ScIcon name="cloudCheckLayeredHome" class="me-1 align-middle text-success"/>
                                Published
                            </div>

                            <div v-else-if="( pres.publish_status==='published' && pres.has_changes )" class="updates">

                                <!-- !!IMPORTANT d-inline-block and width are needed to make clouds appear same size !! -->
                                <ScIcon name="cloudFasHome" class="align-middle text-orange d-inline-block" style="width: 2em"/>
                                <ScIcon name="longArrowRight" class="mx-2 align-middle"/>
                                <ScIcon name="cloudCheckLayeredHome" class="me-1 align-middle text-success"/>

                                Changes available &nbsp; &middot; &nbsp;
                                <a href="#" @click.prevent="showPresPublish()" v-sc-trackevent="'list:publish_popover'">Publish</a>
                            </div>

                            <div v-else class="unpublished">

                                <ScIcon name="cloudTimesLayeredHome" class="me-1 align-middle text-danger"/>
                                Unpublished &nbsp; &middot; &nbsp;
                                <a href="#" @click.prevent="showPresPublish()" v-sc-trackevent="'list:publish_popover'">Publish</a>
                            </div>

                        </div>

                        <ul class="nav nav-pills mt-4">
                            <li v-if="presIsEditable"
                                class="nav-item my-auto mx-4">
                                <router-link :to="{ name: 'editor', params: {
                                    workshopId: mainMxCurrentWorkshopId, showcaseId: pres.id }}"
                                    class="btn-link">
                                    <ScIcon name="editFW"/>
                                    Edit</router-link></li>
                            <li class="nav-item my-auto mx-4">
                                <router-link :to="{name: 'workshop-home-preview', params: {
                                    workshopId: this.mainMxCurrentWorkshopId, showcaseId: this.pres.id}}"
                                     class="btn-link">
                                    <ScIcon name="playCircleFW"/>
                                    Preview</router-link></li>
                            <li v-if="['unpublished', 'published'].indexOf(pres.publish_status) !== -1"
                                class="nav-item my-auto mx-4">
                                <a href="#" @click.prevent="showManageAccess()" class="btn-link">
                                    <ScIcon name="usersFW"/>
                                    Manage Access</a></li>
                            <li v-if="presIsEditable"
                                class="nav-item my-auto mx-4">
                                <a href="#" @click.prevent="showPresSharing" class="btn-link">
                                    <ScIcon name="shareSquareFW"/>
                                    Manage Sharing</a></li>
                            <li class="nav-item dropdown navbar-light my-auto mx-4">
                                <button class="btn dropdown-toggle btn-link" data-bs-toggle="dropdown" role="button"
                                        aria-expanded="false">More</button>
                                <div class="dropdown-menu">

                                    <router-link v-if="presIsEditable" :to="{ name: 'outline', params: {
                                            workshopId: mainMxCurrentWorkshopId, showcaseId: pres.id }}"
                                                 class="dropdown-item">Outline</router-link>
                                    <router-link :to="{ name: 'reporting-activity', params: { workshopId: mainMxCurrentWorkshopId}, query: { scid: pres.id }}"
                                                 class="dropdown-item">Activity Log</router-link>
                                    <router-link v-if="pres.version > 0"
                                                 :to="{ name: 'reporting-analytics', params: { workshopId: mainMxCurrentWorkshopId}, query: { scid: pres.id }}"
                                                 class="dropdown-item">Analytics</router-link>
                                    <router-link v-if="pres.publish_status==='published'"
                                                 :to="{ name: 'email-viewers', params: { workshopId: mainMxCurrentWorkshopId, showcaseId: pres.id }}"
                                                 class="dropdown-item">Email Users</router-link>
                                    <div v-if="!pres.is_read_only && pres.publish_status === 'published'"
                                        class="dropdown-divider"></div>
                                    <button v-if="!pres.is_read_only && pres.publish_status === 'published'" type="button"
                                        @click.prevent="showPresHistory()" class="dropdown-item">History</button>
                                    <button v-if="!pres.is_read_only && pres.publish_status === 'published'" type="button"
                                        @click.prevent="showPresCopy()" class="dropdown-item">Copy</button>
                                    <button v-if="!pres.is_read_only && pres.publish_status === 'published'" type="button"
                                       @click.prevent="showPresChangeStatus(pres.publish_status, 'unpublished')" class="dropdown-item">Unpublish</button>
                                    <button v-if="!pres.is_read_only && ['published', 'unpublished'].indexOf(pres.publish_status) !== -1" type="button"
                                       @click.prevent="showPresChangeStatus(pres.publish_status, 'archived')" class="dropdown-item">Archive</button>
                                    <button v-if="!pres.is_read_only && pres.publish_status === 'archived'" type="button"
                                       @click.prevent="showPresChangeStatus(pres.publish_status, 'unpublished')" class="dropdown-item">Unarchive</button>
                                    <div v-if="!pres.is_read_only" class="dropdown-divider"></div>
                                    <button v-if="presIsEditable" type="button"
                                            @click.prevent="showPresFileUsages()" class="dropdown-item">Replace Files</button>
                                    <button v-if="!pres.is_read_only" type="button"
                                            @click.prevent="showPresDelete()" class="dropdown-item">Delete</button>
                                    <button v-if="!pres.is_read_only" type="button"
                                            @click.prevent="showPresExport()" class="dropdown-item">Export</button>
                                    <div v-if="presIsEditable" class="dropdown-divider"></div>
                                    <button v-if="presIsEditable" type="button"
                                            @click.prevent="showPresRename()" class="dropdown-item">Rename</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    import PresentationHistory from './presentation/PresentationHistory.vue';
    import PresentationPublish from './presentation/PresentationPublish.vue';
    import PresentationChangeStatus from './presentation/PresentationChangeStatus.vue';
    import AssignLabelsModal from './presentation/labels/AssignLabelsModal.vue';
    import PresentationDelete from './presentation/PresentationDelete.vue';
    import PresentationExport from './presentation/PresentationExport.vue';
    import PresentationCopy from './presentation/PresentationCopy.vue';
    import PresentationShareable from './presentation/PresentationShareable.vue';
    import PresentationFileUsages from './presentation/PresentationFileUsages.vue';
    import ManageAccessModal from './presentation/manage-access/ManageAccessModal.vue';
    import MainAppMixin from './MainAppMixin';
    import ScProgressCircle from './global/ScProgressCircle.vue';
    import ScConfirmModal2 from '../shared/common/ScConfirmModal2.vue';
    import ScIcon from '../shared/common/ScIcon.vue';
    import ScRenameModal2 from '../shared/common/ScRenameModal2.vue';
    import PresThumbWithChassis from './presentation/PresThumbWithChassis.vue';

    export default {
        name: 'WorkshopHomePresentation',
        mixins: [MainAppMixin],
        components: {ScProgressCircle, PresThumbWithChassis, ScIcon},
        props: {
            pres: {type: Object, default: null}
        },
        methods: {
            showPresHistory () {
                PresentationHistory.modal(this.mainMxCurrentWorkshopId, this.pres.id);
            },
            showPresPublish () {
                let confirmFirst = this.pres.publish_status !== "published";
                let permissions = this.pres.permissions ? this.pres.permissions : '';
                PresentationPublish.modal(this.mainMxCurrentWorkshopId, this.pres.id, permissions,
                    confirmFirst, false, this.pres.template);
            },
            showPresChangeStatus (oldStatus, newStatus) {
                PresentationChangeStatus.modal(this.pres.id, this.mainMxCurrentWorkshopId, false,
                  oldStatus, newStatus);
            },
            showPresCopy () {
                PresentationCopy.modal(this.pres.id, this.mainMxCurrentWorkshopId);
            },
            showPresDelete () {
                PresentationDelete.modal(this.pres.id, this.mainMxCurrentWorkshopId, false);
            },
            showPresExport: function() {
                PresentationExport.modal(this.pres.id);
            },
            showPresRename () {
                ScRenameModal2.modal({name: this.pres.title, maxLength: 128}, (newTitle) => {
                    //console.log('got back', newTitle);
                    return this.$store.dispatch('plistSetTitle', {
                        workspaceId: this.mainMxCurrentWorkshopId, presId: this.pres.id, newTitle: newTitle
                    });
                });
            },
            showPresFileUsages () {
                PresentationFileUsages.modal(this.pres.id,
                    (pageId) => { // nav to page callback
                        this.$router.push({
                            name: 'editor',
                            params: {workshopId: this.mainMxCurrentWorkshopId, showcaseId: this.pres.id},
                            query: {pid: pageId}
                        });
                    }, () => {  // replacements performed callback
                        this.$store.commit('plistPresHasChanges', this.pres.id);
                    }
                );
            },
            showPresSharing () {
                PresentationShareable.modal(this.pres.id, null, null, null);
            },
            showManageAccess () {
                ManageAccessModal.modal({
                    presentation_id: this.pres.id, presentation_read_only: this.pres.is_read_only
                });
            },
            showManageLabels () {
                AssignLabelsModal.modal(this.pres.id);
            },
            cancelImport () {
                ScConfirmModal2.modal('Are you sure you want to cancel this import?').then(() => {
                    this.$store.dispatch('plistCancelImport', {
                        presentationId: this.pres.id, workspaceId: this.mainMxCurrentWorkshopId});
                }, () => {});
            }

        },
        computed: {
            presIsEditable() {
              return ['unpublished', 'published'].indexOf(this.pres.publish_status) !== -1 && !this.pres.is_read_only;
            },
            userPrefs () {
                return this.$store.state.user;
            },
            presLabels () {
                let labelsForPres = [];
                Object.values(this.$store.state.plist.labels).forEach((label) => {
                    if (label.presentation_ids.indexOf(this.pres.id) !== -1) {
                        labelsForPres.push(label);
                    }
                });
                return labelsForPres.slice().sort((a,b) => a.name.localeCompare(b.name));
            }

        }
    }

</script>