<template>
  <div class="label-search-sort-bar">
    <div class="ms-md-2 ms-0 clearfix">

      <div class="float-end me-2 mb-2">
        <button class="btn px-2" @click.prevent="refreshShowcases" :disabled="loadingPresentations"
                v-sc-tooltip="'Refresh'">
          <ScIcon v-if="!loadingPresentations" name="syncAltFW" class="text-muted"/>
          <ScIcon v-if="loadingPresentations" name="syncAltFWSpin" class="text-muted"/>
        </button>
      </div>

      <div class="input-group float-end me-1 mb-2" style="width: 160px;">
        <input type="search" placeholder="Search" class="form-control showcase-title-filter"
               v-model.trim="searchVal" size="5">
        <button v-sc-tooltip="'Filter by title'" @click.prevent
                class="btn btn-outline-secondary input-group-text action-filter-showcases"><ScIcon name="search"/></button>
      </div>

      <div class="float-end mb-2 ms-2 d-flex flex-nowrap">
        <div v-if="canFilter" :class="['dropdown me-2', presListFilter === 'show-default' ? 'pres-filter-default' : 'pres-filter-active']">
          <button type="button" class="btn text-center btn-outline-secondary btn-pres-filter dropdown-toggle ps-2 pe-3"
                  data-bs-toggle="dropdown" role="button" aria-expanded="false">
            <ScIcon name="filterFW" v-sc-tooltip="'Filter'"  />
          </button>
          <div class="dropdown-menu pe-2">
            <button type="button" @click.prevent="setPresListFilter('show-default')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListFilter === 'show-default' ? 'active' : '']">
              <ScIcon v-if="presListFilter === 'show-default'" name="check" class="me-2"/> Published and Unpublished
            </button>
            <button type="button" @click.prevent="setPresListFilter('show-unpublished')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListFilter === 'show-unpublished' ? 'active' : '']">
              <ScIcon v-if="presListFilter === 'show-unpublished'" name="check" class="me-2"/> Unpublished
            </button>
            <button type="button" @click.prevent="setPresListFilter('show-published')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListFilter === 'show-published' ? 'active' : '']">
              <ScIcon v-if="presListFilter === 'show-published'" name="check" class="me-2"/> Published
            </button>
            <button type="button" @click.prevent="setPresListFilter('show-archived')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListFilter === 'show-archived' ? 'active' : '']">
              <ScIcon v-if="presListFilter === 'show-archived'" name="check" class="me-2"/> Archived
            </button>
            <button type="button" @click.prevent="setPresListFilter('show-templates')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListFilter === 'show-templates' ? 'active' : '']">
              <ScIcon v-if="presListFilter === 'show-templates'" name="check" class="me-2"/> Templates
            </button>
          </div>
        </div>
        <div class="dropdown me-2">
          <button type="button" class="btn text-center btn-outline-secondary dropdown-toggle ps-2 pe-3"
                  data-bs-toggle="dropdown" role="button" aria-expanded="false">
            <ScIcon v-if="presListOrder === 'id_desc'" name="clockFW" v-sc-tooltip="'Sort order'" />
            <ScIcon v-if="presListOrder === 'id_asc'" name="clockFW" v-sc-tooltip="'Sort order'" />
            <ScIcon v-if="presListOrder === 'name_asc'" name="sortAlphaDownFW" v-sc-tooltip="'Sort order'" />
          </button>
          <div class="dropdown-menu pe-2">
            <button type="button" @click.prevent="setSortType('id_asc')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListOrder === 'id_asc' ? 'active' : '']">
              <ScIcon name="clockFW" class="me-2"/> Most recent
            </button>
            <button type="button" @click.prevent="setSortType('id_desc')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListOrder === 'id_desc' ? 'active' : '']">
              <ScIcon name="clockFW" class="me-2"/> Least recent
            </button>
            <button type="button" @click.prevent="setSortType('name_asc')" style="white-space: nowrap;"
                    :class="['dropdown-item text-start', presListOrder === 'name_asc' ? 'active' : '']">
              <ScIcon name="sortAlphaDownFW" class="me-2"/> A-Z
            </button>
          </div>
        </div>
      </div>

      <div class="mt-0 mb-4 d-flex-block">
        <div id="label-list-view" style="min-height: 30px"><!-- min-height to make sure it looks good with no labels -->
          <div v-for="label in labels" :key="label.id"
               class="badge me-1 label-sc-pres fw-bold fs-md mb-1"
               :style="{ border: '4px solid ' + label.bg_color,
                         opacity: (selectedLabelIds.length === 0 || selectedLabelIds.indexOf(label.id) !== -1 ? '1.0' : '0.4'),
                         backgroundColor: label.bg_color}">
            <a href="#" @click.prevent="toggleLabel(label.id)" class="toggle-label text-white">{{ label.name }}</a>
          </div>
          <div v-if="manageLabelsEnabled"
               class="badge btn text-muted ms-auto label-sc-pres fw-bold fs-md mb-1"
               v-sc-tooltip="'Manage Labels'"
               @click.prevent="openManageAllLabelsModal">
            <ScIcon name="tagFW" class="h5 mb-0 text-muted" style="line-height: 0"/></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

    import ScIcon from '../../../shared/common/ScIcon.vue';

    export default {
      name: "LabelSearchSortBar",
      components: {ScIcon},
      emits: ['toggle-label', 'set-sort-type', 'refresh-showcases-list', 'open-manage-all-labels-modal',
              'update-search-val', 'set-pres-list-filter'],
      props: {
        labels: {type: Array, default: null},
        selectedLabelIds: {type: Array, default: null},
        manageLabelsEnabled: {type: Boolean, default: false},
        presListOrder: {type: String, default: 'id_desc'},
        loadingPresentations: {type: Boolean, default: false},
        canFilter: {type: Boolean, default: false},
        presListFilter: {type: String, default: null}
      },
      data () {
        return {
          searchVal: null,
        }
      },
      methods: {
        toggleLabel (labelId) {
          this.$emit('toggle-label', labelId);
        },
        setPresListFilter(presListFilter) {
          this.$emit('set-pres-list-filter', presListFilter);
        },
        setSortType (type) {
          this.$emit('set-sort-type', type);
        },
        refreshShowcases () {
          // console.log('trigger refresh')
          this.$emit('refresh-showcases-list');
        },
        openManageAllLabelsModal () {
          this.$emit('open-manage-all-labels-modal');
        }
      },
      watch: {
        searchVal (newVal) {
          this.$emit('update-search-val', newVal);
        }
      }
    }
</script>

<style scoped>

/* weird bug with bootstrap, if we change the class on the dropdown button it causes vue to rerender and bootstrap to
  lose the ability to toggle the dropdown.  so apply styles with a hack :( */
.pres-filter-active .btn-pres-filter  {
    border-color: #17a2b8;
    background-color: #17a2b8;
    color: white !important;
}
.pres-filter-active .btn-pres-filter:hover {
    color: white !important;
}

</style>