<template>
  <div v-if="slideDetails" class="justify-content-end card-body sc-detail-view p-3">
    <div class="text-center px-3 row">
      <div class="w-100 hidden-print pe-4 my-2 text-end">
        <span class="d-inline-block me-2">
          <router-link :to="{ name: 'editor',
                              params: { workshopId: mainMxCurrentWorkshopId, showcaseId: presentationId },
                              query:  { pid: slideDetails.id }}">
            <ScIcon name="editFW" class="me-1"/>Edit</router-link></span>
        <span class="d-inline-block">
          <router-link :to="{ name: 'outline-preview',
                              params: { workshopId: mainMxCurrentWorkshopId, showcaseId: presentationId },
                              query:  { pid: slideDetails.id }}">
            <ScIcon name="playCircleFW" class="me-1"/>Preview</router-link></span>
      </div>
      <div class="w-100">
        <div class="mt-auto pe-3 text-start">
          <strong class="mb-0 me-2 h3">{{ slideDetails.alpha_num_name }} </strong>
          <span class="h3 fw-normal text-break">{{ formatSlideName }}</span>
        </div>
      </div>
    </div>
    <div class="mt-2 px-3">
      <template v-if="slideDetails.has_intro_video">
        <span v-sc-tooltip="'Intro Video'">
          <ScIcon name="fileVideoFW" class="me-1"/>
          {{ slideDetails.intro_video_res.name }}
        </span>
        <span class="text-muted ms-1">{{ formatToFileSizeWithBytes(getResByteSize(slideDetails.intro_video_res)) }}</span>
        <a href="#" v-sc-trackevent="'outline:resource_info_popover'"
           class="hidden-print resource-info-popover-link"
           @click.prevent="mainMxShowResInfo(slideDetails.intro_video_res.id)">
          <ScIcon name="infoCircle" class="ms-1"/>
        </a>
        <br/>
      </template>
      <template v-if="slideDetails.background_src_resource_id">
        <span v-if="getBgName(slideDetails)">
          <span v-sc-tooltip="'Background'">
            <ScIcon name="fileImageFW" class="me-1"/>
            {{ getBgName(slideDetails) }}
          </span>
          <a href="#" v-sc-trackevent="'outline:resource_info_popover'" class="hidden-print resource-info-popover-link"
             @click.prevent="mainMxShowResInfo(slideDetails.background_src_resource_id)">
            <ScIcon name="infoCircle" class="ms-1"/>
          </a>
          <br/>
        </span>
      </template>
      <template v-if="slideDetails.page_animated_bg_id">
        <span v-if="getAnimatedBgName(slideDetails)">
          <span v-sc-tooltip="'Background Cinemagraph'">
            <ScIcon name="fileVideoFW" class="me-1"/>
            {{ getAnimatedBgName(slideDetails) }}
          </span>
          <a href="#" v-sc-trackevent="'outline:resource_info_popover'" class="hidden-print resource-info-popover-link"
             @click.prevent="mainMxShowResInfo(slideDetails.page_animated_bg_id)">
            <ScIcon name="infoCircleFW"/>
          </a>
          <br/>
        </span>
      </template>
      <template v-if="slideDetails.has_prev_slide || slideDetails.has_next_slide">
        <span v-if="slideDetails.has_prev_slide">
          <span v-sc-tooltip="'Swipe Left'" class="me-3">
            <a :href="formatForwardLink(slideDetails.prev_slide.id)"
               @click.prevent="changeDetailView(slideDetails.prev_slide.id)">
              <ScIcon name="handPointLeftFW" class="me-1"/>{{slideDetails.prev_slide.alpha_num_name}}</a>
          </span>
        </span>
        <span v-if="slideDetails.has_next_slide">
          <span v-sc-tooltip="'Swipe Right'">
            <a :href="formatForwardLink(slideDetails.next_slide.id)" class="ms-1"
               @click.prevent="changeDetailView(slideDetails.next_slide.id)">
              {{slideDetails.next_slide.alpha_num_name}}
              <ScIcon name="handPointRightFW" class="ms-1"/></a>
          </span>
        </span>
        <br/>
      </template>
      <template v-if="slideDetails.has_incoming_link_page">
        <span v-sc-tooltip="'Slides that link to ' + slideDetails.alpha_num_name">
          <ScIcon name="replyFW"/>
          <span v-for="(link, idx) in slideDetails.incoming_link_pages" :key="link.id + '-' + idx">
            <a :href="formatForwardLink(link.id)" class="ms-3"
               @click.prevent="changeDetailView(link.id)">
              {{ link.alpha_num_name }}</a>
          </span>
        </span>
        <br/>
      </template>
      <template v-if="slideDetails.has_hotspots">
        <div class="mt-2">
          <small>Hotspots</small>
        </div>
        <div class="hotspots-display">
          <OutlineHotspots :slideDetails="slideDetails"
                           :scData="scData"
                           @show-detail-view="changeDetailView"></OutlineHotspots>
        </div>
      </template>
      <template v-if="slideDetails.shareableOnSlide.length > 0">
        <div class="mt-2">
          <small>Shareable on this slide
            <ScIcon name="questionCircleFW" v-sc-tooltip="'To change this, click Manage Sharing from Editor or Home'"/>
          </small>
          <div v-for="item in sortShareableOnSlide" :key="'shareableItem' + item.id">
            <ScIcon :name="shareableIconName(item)" class="me-1"/>
            {{ item.name }}
            <span class="text-muted mx-1 d-inline-block">{{ formatToFileSizeWithBytes(getResByteSize(item)) }}</span>
            <a href="#" v-sc-trackevent="'outline:resource_info_popover'" class="hidden-print resource-info-popover-link"
               @click.prevent="mainMxShowResInfo(item.id)"><ScIcon name="infoCircle"/></a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

    import constants from '../../../constants';
    import MainAppMixin from '../../MainAppMixin';
    import OutlineHotspots from './OutlineHotspots.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import ScCommonUtil from '../../../shared/common/ScCommonUtil';
    import OutlineUtil from "./OutlineUtil";

    export default {
      name: 'OutlineDetails',
      emits: ['show-detail-view'],
      mixins: [ MainAppMixin ],
      components: { OutlineHotspots, ScIcon },
      props: {
        slideDetails: {type: Object, default: null},
        scData: {type: Object, default: null},
        presentationId: {type: Number, default: null},
      },
      methods: {
        getBgName (page) {
          let res = this.scData.resource[page['background_src_resource_id']];
          if (res && res['name'] && res['name'] !== '')
            return res['name'];
        },
        getAnimatedBgName (page) {
          let res = this.scData.resource[page['page_animated_bg_id']];
          if (res && res['name'] && res['name'] !== '')
            return res['name'];
        },
        changeDetailView (pageId) {
          if (!pageId) return;
          this.$emit('show-detail-view', pageId);
        },
        shareableIconName (item) {
          return constants.RES_CONTENT_TYPE_TO_ICON[item.content_type];
        },
        formatForwardLink (pageId) {
          return (pageId) ? `#slide-id/${pageId}` : '';
        },
        getResByteSize (item) {
          return OutlineUtil.getResByteSize(item, this.scData);
        },
        formatToFileSizeWithBytes (val) {
          return (val > 0) ? ScCommonUtil.filesizeWithBytes(val) : 0;
        }
      },
      computed: {
        formatSlideName () {
          return (!this.slideDetails.title || this.slideDetails.title === 'None') ? '' : this.slideDetails.title;
        },
        sortShareableOnSlide () {
          let sorted = this.slideDetails.shareableOnSlide;
          return sorted.sort(function(a, b) {
            let nameA = a.name.toLowerCase();
            let nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
        },
      },
    }
</script>

<style scoped>

    .visible-print-inline{
        display:none
    }

</style>