<template>
    <div class="mb-4 pb-4 border-bottom">
        <h3>
            <button v-if="showMore" class="btn btn-link float-end fw-bold"  @click.prevent="showMore=false">
                Show less <ScIcon name="angleUp" class="ms-1"></ScIcon></button>
            <button v-if="!showMore" @click.prevent="showMore=true"
                    class="btn btn-link float-end fw-bold">
                Show more <ScIcon name="angleDown" class="ms-1"></ScIcon></button>
            System Templates
        </h3>

        <div v-if="isLoading" class="mt-3 d-flex align-items-center justify-content-center"
                style="height: 217px;"><!-- same height as items div -->
            <ScIcon name="spinnerFW" class="text-muted"/>
        </div>

        <div v-if="!isLoading">
            <div :class="['d-flex align-items-center', showMore ? 'flex-wrap' : 'flex-nowrap']"
                    :style="{'overflow-x': 'auto'}">
                <div v-for="(item, idx) in samplesInRandomOrder" :key="'img-' + idx"
                     class="pres-sys-template-preview text-center">
                    <button type="button" class="btn btn-link" @click.prevent="createPres(item.template_uuid)">
                        <PresThumbWithChassis :pres="item" :thumbScale="0.8"></PresThumbWithChassis>
                        <br><strong>{{ item.title }}</strong>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import MainAppMixin from '../MainAppMixin';
    import PresThumbWithChassis from '../presentation/PresThumbWithChassis.vue';

    export default {
        name: 'NewFromSystemTemplate',
        mixins: [MainAppMixin],
        components: {PresThumbWithChassis, ScIcon},
        data () {
            return {
                isLoading: false,
                loadedData: null,
                showMore: false,
                presAreOverflowing: false,
            };
        },
        mounted () {
            this.loadTemplates();
        },
        methods: {
            loadTemplates () {
                this.isLoading = true;
                $.ajax({
                    method: 'GET', url: '/main/new_presentation/ajax_sample_showcases',
                    data: {workspace_id: this.mainMxCurrentWorkshopId}
                }).done((data) => {
                    this.isLoading = false;
                    this.loadedData = data.samples;
                }).fail((jqXhr) => {
                    this.isLoading = false;
                    ScNotification.growlXhrError(jqXhr, 'loading examples');
                });
            },
            createPres(templateUuid) {
                this.$emit('create-pres', templateUuid);
            },
            templatesContainerUpdated(e) {
                console.log('templatesContainerUpdated', e.el.clientWidth, e.el.scrollWidth);
                this.presAreOverflowing = e.el.scrollWidth > e.el.clientWidth;
            }

        },

        computed: {
            samplesInRandomOrder() {
                if (!this.loadedData) return [];
                return Object.values(this.loadedData)
                  .map((a) => ({sort: Math.random(), value: a}))
                  .sort((a, b) => a.sort - b.sort)
                  .map((a) => a.value);
            }
        }

    }

</script>

<style>

.pres-sys-template-preview:hover button img {
    border: 1px solid white;
}

</style>
<style scoped>


</style>
