/**
 *
 * Analytics
 *
 *
 */

/* public */
/* public */
/* public */
/* public */

import _ from "underscore"; // throttle
import ScCommonUtil from "../../shared/common/ScCommonUtil";

export default {

    record: function(event_type, argsObj, workspaceId, userId, sharedId) {
        //console.log('analytics.js record sc', event_type);

        if (!argsObj) argsObj = {};
        let language = '';
        if (typeof navigator.language === 'string') language = navigator.language.toLowerCase();

        let obj = {
            client_name: 'browser',  // The app title  eg, apple_app, chrome_app
            // skip client_version, web is always latest

            user_timezone: new Date().getTimezoneOffset(),  // the difference, in minutes, between UTC and local time
            user_language: language,  // The language part of the locale, 2 letter iso code eg, en

            // skip device_identifier on the web  as it doesn't have much meaning
            // skip device_model, device_os, device_os_version, these will be loaded from userAgent http header

            device_viewport: document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight,  // current size of viewport  eg, 1024x768
                                                                            // (in real device pixels!, eg, 2048 not 1024 or pixels not points)
            // event
            event_type: event_type,  // one of: workshop_view, showcase_view, page_view, resource_view, screensaver_view, search_view
            event_timestamp_ms: (new Date()).getTime()
        };
        // skip event_timestamp, we are online, server will decide

        // note, for regular users "device_identifier" is overriden based on auth token, for sharing recipients it's not
        obj.device_identifier = _scDevId;
        obj.tab_id = _tabIdV2;
        obj.workshop_id = 0;
        obj.user_id = 0;  // default to 0 for anonymous
        obj.uuid = ScCommonUtil.guid();
        obj.prev_uuid = _prevEventUuid;

        if ( workspaceId ) obj.workshop_id = workspaceId;
        if ( userId ) obj.user_id = userId;
        if ( sharedId ) obj.shared_id = sharedId;

        if ( argsObj.showcase_id ) obj.showcase_id = argsObj.showcase_id;
        if ( argsObj.showcase_version ) obj.showcase_version = argsObj.showcase_version;
        if ( argsObj.resource_id ) obj.resource_id = argsObj.resource_id;
        if ( argsObj.page_id ) obj.page_id = argsObj.page_id;
        if ( argsObj.message ) obj.message = argsObj.message;

        _analyticsQueue.push(obj);

        _prevEventUuid = obj.uuid;
        _sendAnalyticsThrottled();
    }

};


/* private */
/* private */
/* private */
/* private */

let _analyticsQueue = [];
let _prevEventUuid = null;

let _tabIdV2 = 0;
if (typeof window.sessionStorage === 'object' && typeof window.localStorage === 'object') {
    // if browser supports sessionStorage use it to store an id
    if (window.sessionStorage.getItem('scTabIdV2')) {
        _tabIdV2 = Number(window.sessionStorage.getItem('scTabIdV2'));
    }   else {
        let tabIdMaxV2 = 0;
        if (window.localStorage.getItem('scTabIdMaxV2')) {
            tabIdMaxV2 = Number(window.localStorage.getItem('scTabIdMaxV2'));
        }
        if (tabIdMaxV2 > 30000) tabIdMaxV2 = 1;
        _tabIdV2 = tabIdMaxV2+1;
        window.localStorage.setItem('scTabIdMaxV2', String(_tabIdV2));
        window.sessionStorage.setItem('scTabIdV2', String(_tabIdV2));
    }
}

let _scDevId = ScCommonUtil.guid();
if (typeof window.localStorage === 'object') {
    if (!window.localStorage.getItem('scDevId')) window.localStorage.setItem('scDevId', _scDevId);
    _scDevId = window.localStorage.getItem('scDevId');
}

let _sendAnalytics = function() {
    let requestTimeout = setTimeout(function() {
        xmlhttp.abort();
    }, 120 * 1000);  // timeout after 2 mins

    let xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState === 4 ) {
            clearTimeout(requestTimeout);
            if(xmlhttp.status === 200){
                //console.log('worker-sc-analytics.js post ok');
            }   else {
                // it failed, ignore it for now, user probably lost network connection
            }
        }
    };
    xmlhttp.open('POST', '/api/analytics_web_v1', true);
    xmlhttp.setRequestHeader('Content-type', 'application/json');

    let dataBatch = [];
    while (dataBatch.length < 10 && _analyticsQueue.length > 0) {
        let toProcess = _analyticsQueue.shift();  // take the oldest item from queue
        dataBatch.push(toProcess);
    }
    //console.log('send', dataBatch.length, 'items');
    xmlhttp.send(JSON.stringify({batch: dataBatch, backlog: _analyticsQueue.length}));
};

let _sendAnalyticsThrottled = _.throttle(_sendAnalytics, 2000);



