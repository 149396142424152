/**
 *
 * Showcase Viewer Pre Loader
 *
 *
 */

/* public */
/* public */
/* public */
/* public */

import _ from "underscore"; // sortBy, where, contains, isString, each, findWhere, findIndex


let BrowserViewerPreload = {

    preloadNow(data, pageId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                BrowserViewerPreload.preloadRightNow(data, pageId).then(() => {
                    resolve()
                });  // delay slightly

            }, 1);
        });
    },

    preloadRightNow(data, pageId) {
        //cache all resource items
        return new Promise((resolve) => {
            //console.log('start caching v2', pageId);
            let thumbResourcesToCache = [];
            let initialPreLoad = false;
            if (!pageId) {
                initialPreLoad = true;
                let rootPages = _.sortBy(_.where(data.page, {pagelist_id: data.presentationmetadata.root_pagelist_id}), 'sort_order');
                if (!rootPages || !rootPages[0]) return;
                pageId = rootPages[0].id;
            }

            // remove any we already asked to precach in case they didn't get downloaded yet
            let elems = document.getElementsByTagName('link');
            for (let e of elems) {
                if (e.class && e.class === 'sc-res-prefetch') e.parentNode.removeChild(e);
            }

            let cacheItem = (itemToCache) => {
                let preloadLink = document.createElement("link");
                preloadLink.href = itemToCache.url;
                preloadLink.rel = "preload";
                preloadLink.class = "sc-res-prefetch";
                preloadLink.as = itemToCache.as;
                preloadLink.type = itemToCache.type;
                //console.log('adding', itemToCache.as, itemToCache.url);
                document.head.appendChild(preloadLink);
            };

            // suggest: cache the top level pages first and opening video then go down the stack
            let cacheResourceId = (resourceId, urlKey) => {
                let resource = data.resource[resourceId];
                if (!resource) return;
                if (!_.contains(_supportedSuffixes, resource.suffix)) return;  // cache stuff we support in browser
                if (!_.isString(resource[urlKey]) ) return;
                //console.log('need to cache', resource.id, resource.name, urlKey);
                cacheItem({url: resource[urlKey], id: resourceId, as: _suffixToAs[resource.suffix],
                    type: _suffixToType[resource.suffix]});
            };

            let cachePage = (page, cacheTargetPages, tag) => {
                //console.log('cache page', page.alpha_num_name, tag);
                if (page.background_resource_id) cacheResourceId(page.background_resource_id, 'url');
                if (page.resource_pagethumb_id) thumbResourcesToCache.push(page.resource_pagethumb_id);
                let hotspots = _.where(data.hotspot, {parent_page_id: page.id});
                _.each(hotspots, (hotspot) => {
                    if (hotspot.client_resource_id) cacheResourceId(hotspot.client_resource_id, 'url');
                    if (hotspot.target_resource_id) cacheResourceId(hotspot.target_resource_id, 'url');
                    if (hotspot.target_page_id && cacheTargetPages) {
                        let targetPage = _.findWhere(data.page, {id: hotspot.target_page_id});
                        if (targetPage) cachePage(targetPage, false, tag + ' child');
                    }
                });
            };

            if ( initialPreLoad && data.presentationmetadata.opening_scene_resource_id ) {
                cacheResourceId(data.presentationmetadata.opening_scene_resource_id);
            }

            // note: we don't bother to preload the screensaver, it can just load if the timeout is reached

            let page = _.findWhere(data.page, {id: pageId});
            //console.log('page', page);
            if (!page) return;
            let pages = _.sortBy(_.where(data.page, {pagelist_id: page.pagelist_id}), 'sort_order');
            //console.log('pages', pages);
            let primaryPageIdx = _.findIndex(pages, (p) => { return p.id === pageId });
            //console.log('primaryPageIdx', primaryPageIdx);
            if (primaryPageIdx === -1) return;

            cachePage(pages[primaryPageIdx], true, 'primary page');
            if (pages[primaryPageIdx+1]) cachePage(pages[primaryPageIdx+1], false, 'next'); // cache the page after
            if (pages[primaryPageIdx+2]) cachePage(pages[primaryPageIdx+2], false, 'next +1'); // cache the page 2 after
            if (pages[primaryPageIdx+3]) cachePage(pages[primaryPageIdx+3], false, 'next +2'); // cache the page 2 after
            if (pages[primaryPageIdx-1]) cachePage(pages[primaryPageIdx-1], false, 'previous'); // cache the page before

            if ( thumbResourcesToCache.length > 0) {
                _.each(thumbResourcesToCache, function(thumbResourceToCache) {
                    cacheResourceId(thumbResourceToCache, 'thumb_url')
                });
            }

            resolve()
        });
    }
};

export default BrowserViewerPreload;

/* private */
/* private */
/* private */
/* private */

let _supportedSuffixes = ['png', 'jpg', 'gif'];  // disable 'mp4' and 'pdf' as they don't get preloaded anyway
let _suffixToAs = {'png': 'image', 'jpg': 'image', 'gif': 'image', 'mp4': 'video', 'pdf': 'image'};
let _suffixToType = {'png': 'image/png', 'jpg': 'image/jpg', 'gif': 'image/giv', 'mp4': 'video/mp4',
    'pdf': 'application/pdf'};

/* app lifecycle events */
/* app lifecycle events */
/* app lifecycle events */
/* app lifecycle events */


    