<template>
    <div class="mt-3 mb-4 pb-4 border-bottom">
        <div>
            <button v-if="workshopTemplates.length > 0 && showMore" class="btn btn-link float-end fw-bold"  @click.prevent="showMore=false">
                Show less <ScIcon name="angleUp" class="ms-1"></ScIcon></button>
            <button v-if="workshopTemplates.length > 0 && !showMore && presAreOverflowing"
                    @click.prevent="showMore=true" class="btn btn-link float-end fw-bold">
                Show more <ScIcon name="angleDown" class="ms-1"></ScIcon></button>
            <h3 class="d-inline-block">Workshop Templates</h3>
        </div>

        <div v-if="isLoading" class="mt-3 d-flex align-items-center justify-content-center"
             style="height: 217px;"><!-- same height as items div -->
            <ScIcon name="spinnerFW" class="text-muted"/>
        </div>
        <div v-if="!isLoading">
            <div :class="['d-flex align-items-center', showMore ? 'flex-wrap' : 'flex-nowrap']"
                 :style="{'overflow-x': 'auto'}" ref="systemTemplatesContainer"
                 @vnode-mounted="templatesContainerUpdated" @vnode-updated="templatesContainerUpdated">
                <div v-for="(item, idx) in workshopTemplates" :key="'img-' + idx"
                     class="pres-sys-template-preview text-center">
                    <button type="button" class="btn btn-link" @click.prevent="createPres(item.id)">
                        <PresThumbWithChassis :pres="item" :thumbScale="0.8"></PresThumbWithChassis>
                        <br><strong>{{ item.title }}</strong>
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-3 ms-2 text-muted">
            <small>Make any presentation into a Template via Edit > Settings > Set as Template. Templates must be
            published to show in this list.</small>
        </div>

    </div>
</template>

<script>

import $ from 'jquery';
import MainAppMixin from '../MainAppMixin';
import ScIcon from '../../shared/common/ScIcon.vue';
import ScNotification from "../../shared/common/ScNotification.vue";
import PresThumbWithChassis from "../presentation/PresThumbWithChassis.vue";


export default {
  name: 'NewFromWorkshopTemplate',
  emits: ['create-pres'],
  mixins: [MainAppMixin],
  components: {ScIcon, PresThumbWithChassis},
  data () {
    return {
      isLoading: false,
      workshopTemplates: [],
      showMore: false,
      presAreOverflowing: false
    };
  },
  mounted () {
    this.loadTemplates();
  },
  methods: {
    loadTemplates () {
      this.isLoading = true;
      $.ajax({
        method: 'GET', url: '/main/new_presentation/ajax_workshop_templates',
        data: {workspace_id: this.mainMxCurrentWorkshopId}
      }).done((data) => {
        this.isLoading = false;
        this.workshopTemplates.push(...data.templates);
      }).fail((jqXhr) => {
        this.isLoading = false;
        ScNotification.growlXhrError(jqXhr, 'loading workshop templates');
      });
    },
    createPres(templateId) {
        this.$emit('create-pres', templateId);
    },
    templatesContainerUpdated(e) {
      this.presAreOverflowing = e.el.scrollWidth > e.el.clientWidth;
    }
  },
}

</script>

<style>

.pres-ws-template-preview:hover button img {
    border: 1px solid white;
}

</style>
<style scoped>



</style>
