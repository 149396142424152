<template>
  <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" v-vb-is:modal.show ref="scModal"
         @vb-hidden-bs-modal="isModalShown=false"
         class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content shadow-lg">
          <div class="modal-header">
            <button class="btn-close" data-bs-dismiss="modal"></button>
            <h4 class="modal-title w-100 text-center">Favorites & Tags</h4>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <h3>Slides</h3>
              <ul v-if="userStatsByPage.length > 0"  class="list-unstyled mx-4">
                <li v-for="pageSummary in userStatsByPage" :key="pageSummary.page.id" class="my-2 row">
                  <div class="col-7">
                    <a href="#" @click.prevent="showPageDetails(pageSummary.page)">
                      <strong>{{pageSummary.page.alpha_num_name}}</strong>
                      {{pageSummary.page.title}}
                    </a>
                  </div>
                  <div class="col-5 text-end">
                    <span class="me-3" title="Number of times slide favourited">
                      <ScIcon name="heart" class="me-1"/>
                      {{pageSummary.numLikes}}
                    </span>
                    <span title="Number of times slide used in tag">
                      <ScIcon name="hashtag" class="me-1"/>
                      {{pageSummary.numTags}}
                    </span>
                  </div>
                </li>
              </ul>
              <div v-if="userStatsByPage.length === 0" class="text-center my-2">
                <em class="text-muted">No favorited/tagged slides</em>
              </div>
            </div>

            <div class="mb-4">
              <h3>Files</h3>
              <ul v-if="userLikesByRes.length > 0" class="list-unstyled mx-4">
                <li v-for="resLikeSummary in userLikesByRes" :key="resLikeSummary.resource.id" class="my-2 row">
                  <div class="col-9">
                    {{resLikeSummary.resource.name}}
                    <a href="#" v-sc-trackevent="'outline:resource_info_popover'"
                       class="hidden-print resource-info-popover-link"
                       @click.prevent="showResInfo(resLikeSummary.resource)">
                      <ScIcon name="infoCircle" class="ms-1"/>
                    </a>
                  </div>

                  <div class="col-3 text-end">
                    <span class="me-3" title="Number of times slide favourited">
                      <ScIcon name="heart" class="me-1"/>
                      {{resLikeSummary.numLikes}}
                    </span>
                  </div>
                </li>
              </ul>
              <div v-if="userLikesByRes.length === 0" class="text-center my-2">
                <em class="text-muted">No favorited files</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>

    import _ from "underscore"; // findWhere, sortBy
    import ScIcon from "../../../shared/common/ScIcon.vue";
    import MainAppMixin from "../../../components/MainAppMixin";

    let _ins = null;

    export default {
      name: "OutlineLikesModal",
      mixins: [ MainAppMixin ],
      components: {ScIcon},
      data () {
        return {
          isModalShown: false,
          scData: null,
          scUserPresStats: null,
          showDetailsFn: null
        };
      },
      mounted () {
        _ins = this;
      },
      beforeUnmount() {
        _ins = null;
      },
      methods: {
        hideModal() {
          this.$refs.scModal.$vb.modal.hide();
        },
        showPageDetails(page) {
          this.hideModal();
          this.showDetailsFn(page);
        },
        goToUser(userId) {
          this.$router.push({ name: 'user-view',
                              params: { workshopId: this.mainMxCurrentWorkshopId, userId: userId } });
        },
        showResInfo(res) {
          this.mainMxShowResInfo(res.id)
        },
        toggleBsModal(scUserPresStats, scData, showDetailsFn) {
          this.scUserPresStats = scUserPresStats;
          this.scData = scData;
          this.showDetailsFn = showDetailsFn;
          this.isModalShown = true;
        },
      },
      computed: {
        userStatsByPage() {
          let likesByPage = {};
          let addPageSummaryIfPossible = (pageId) => {
            let page = _.findWhere(this.scData.page, {id: pageId});
            if (page && page.pagelist_id) {
              let pl = _.findWhere(this.scData.pagelist, {id: page.pagelist_id});
              likesByPage[pageId] = {
                page: page, pSortKey: page.sort_order, plSortKey: pl.sequence_num,
                numLikes: 0, numTags: 0
              };
            }
          }
          this.scUserPresStats.page_likes.forEach((summary) => {
            if (!likesByPage[summary.page_id]) addPageSummaryIfPossible(summary.page_id);
            if (likesByPage[summary.page_id]) likesByPage[summary.page_id].numLikes = summary.likes_count;
          });
          this.scUserPresStats.page_tags.forEach((summary) => {
            if (!likesByPage[summary.page_id]) addPageSummaryIfPossible(summary.page_id);
            if (likesByPage[summary.page_id]) likesByPage[summary.page_id].numTags = summary.tag_count;
          });
          return _.sortBy(_.sortBy(Object.values(likesByPage), 'pSortKey'), 'plSortKey');
        },
        userLikesByRes() {
          let likesByRes = {};
          this.scUserPresStats.resource_likes.forEach((summary) => {
            if (!summary.resource_id) return;
            let resource = _.findWhere(this.scData.resource, {id: summary.resource_id});
            if (!resource) return;
            likesByRes[summary.resource_id] = {resource: resource, users: [], sortKey: resource.name,
              numLikes: summary.likes_count};
          });
          return _.sortBy(Object.values(likesByRes), 'sortKey');
        },
      },

      //
      // public functions
      //
      modal: function (scUserPresStats, scData, showDetailsFn) {
        _ins.toggleBsModal(scUserPresStats, scData, showDetailsFn);
      }
    }
</script>
