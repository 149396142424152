let OutlineUtil = {

    getResByteSize (item, scData) {
      if (item.content_type === 'movie') {

        let result2048 = Object.values(scData.resourcemetadata).find(meta => meta.name === 'video_2048_size' && meta.resource_id === item.id);
        if (result2048) {
          item.display_bytesize = Number(result2048.value);
          return item.display_bytesize;
        }

        let result1024 = Object.values(scData.resourcemetadata).find(meta => meta.name === 'video_1024_size' && meta.resource_id === item.id);
        if (result1024) {
          item.display_bytesize = Number(result1024.value);
          return item.display_bytesize;
        }
      }
      return item.bytesize;
    },
}

export default OutlineUtil;