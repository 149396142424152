<template>
    <ScNormalPageLayout headingPart1="File" headingPart2="Library">
        <div class="col p-0">
            <div class="row g-0">
                <LibraryContent libStorePrefix="libPage" style="min-height: 80vh;"
                                class="bg-white w-100"></LibraryContent>
            </div>
        </div>
    </ScNormalPageLayout>
</template>


<script>

    import LibraryContent from './LibraryContent.vue';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue'

    export default {
        name: 'LibraryPage',
        components: { LibraryContent, ScNormalPageLayout },
        mounted: function() {
            setTimeout(() => {
                // libPage store is registered in LibraryContent
                this.$store.commit('libPage/libSetOptions', {
                    selectionMode: 'multiple', dialogType: 'standalone',
                    filterType: 'all'
                })
            });
        }
    }

</script>