<template>
  <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" ref="scModal" v-vb-is:modal.show
          @vb-hidden-bs-modal="isModalShown=false"
          class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">

          <div class="modal-header text-center">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
            <h4 class="modal-title w-100">Manage Labels</h4>
          </div>

          <div class="modal-body clearfix pb-4" style="min-height: 150px">
            <div class="my-4" v-if="loadedLabels">
              <div v-for="(label, idx) in loadedLabels" :key="'ml-' +idx" class=" mb-2">

                <div v-if="!showEditLabelBlock || editLabelIdx !== idx" class="row">

                  <div class="col-8 my-auto">
                    <div class="badge fw-bold fs-md label-sc-pres mb-0 text-truncate"
                         v-sc-tooltip="label.name" style="max-width: 19rem;"
                         :style="{'background-color': label.bg_color}">{{ label.name }}</div>
                  </div>

                  <div class="col-4 ps-0 text-end">
                    <span class="align-middle me-4" v-sc-tooltip="'Number of times used'">
                      {{  label.presentation_ids.length }}
                      <ScIcon v-if="label.presentation_ids.length <= 1" name="tagFW"/>
                      <ScIcon v-if="label.presentation_ids.length > 1" name="tagsFW"/>
                    </span>

                    <button class="btn px-1" v-sc-tooltip="'Edit'" type="button"
                            @click.prevent="editLabel(label,idx)"><ScIcon name="editFW"/></button>
                    <button @click.prevent="sortLabel(label, loadedLabels[idx-1])"
                            :disabled="idx === 0" v-sc-tooltip="'Move Up'" type="button"
                            :style="{visibility: idx === 0 ? 'hidden' : 'visible'}"
                            class="btn px-1"><ScIcon name="sortUp"/></button>
                    <button @click.prevent="sortLabel(label, loadedLabels[idx+1])" type="button"
                            :disabled="idx === loadedLabels.length -1" v-sc-tooltip="'Move Down'"
                            :style="{visibility: idx === loadedLabels.length -1 ? 'hidden' : 'visible'}"
                            class="btn px-1"><ScIcon name="sortDown"/></button>
                  </div>

                </div>

                <ManageLabelsEdit v-if="showEditLabelBlock && editLabelIdx === idx"
                                  :labelBgColor=label.bg_color
                                  :labelName=label.name
                                  :btnTxt='"Save"'
                                  :addingLabel="addingLabel"
                                  @save-label="updateLabel"
                                  @label-edit-close="showEditLabelBlock=false"
                                  @label-delete="deleteLabel(label)" />
              </div>
            </div>

            <div v-if="!showAddLabelOption">
              <button @click.prevent="showAddLabelOption=true" :disabled="addingLabel || tooManyLabels"
                      class="btn btn-outline-secondary">New label</button>
              <span v-if="tooManyLabels" class="ms-3">
                <ScIcon name="exclamationTriangleFW" class="text-danger"/> You can only add up
                to {{ maxNumLabels }} labels</span>
            </div>

            <ManageLabelsEdit v-if="showAddLabelOption"
                              :tooManyLabels="tooManyLabels"
                              :maxNumLabels="maxNumLabels"
                              :addingLabel="addingLabel"
                              @save-label="addNewLabel"
                              @label-edit-close="showAddLabelOption=false" />

          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import ScConfirmModal2 from '../../../shared/common/ScConfirmModal2.vue';
    import ManageLabelsEdit from './ManageLabelsEdit.vue';

    import store from "../../../store/index";
    import constants from "../../../constants";
    import PresentationListStore from "../../../store/PresentationListStore";

    if (!store.state.plist) store.registerModule('plist', PresentationListStore);

    let _ins = null;

    export default {
      name: "ManageAllLabelsModal",
      components: { ManageLabelsEdit, ScIcon },
      props: {
        workspaceId: {type: Number, default: null},
      },
      data () {
        return {
          isModalShown: false,
          showAddLabelOption: false,
          showEditLabelBlock: false,
          editLabelIdx: null,

          newLabelName: null,

          saveLoading: false,
          addingLabel: false
        };
      },

      mounted () {
        _ins = this;
      },
      beforeUnmount() {
        _ins = null;
      },
      methods: {
        editLabel (label, idx) {
          this.showEditLabelBlock = true;
          this.editLabelIdx = idx;
        },

        addNewLabel (val) {
          if (this.tooManyLabels) return;
          if (!val.name || val.name.length < 2) return;

          if (this.willNameDuplicate(val.name, val.bg_color, 0)) {
            ScNotification.growlErrMsg('Duplicate label');
            return;
          }
          this.addingLabel = true;

          $.ajax({
            type: 'POST', url: '/main/presentations/ajax_new_label',
            data: {
              name: val.name,
              bg_color: val.bg_color,
              presentation_ids: [],
              workspace_id: this.workspaceId
            }
          }).done((data) => {
            this.$store.commit('plistNewLabel', {
              id: data.label_id,
              name: val.name,
              bg_color: val.bg_color,
              presentation_ids: [],
              sort_key: data.sort_key,
              workspace_id: this.workspaceId
            });
            this.showAddLabelOption = false;

          }).always(() => {
            this.addingLabel = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'adding label');
          });

        },

        updateLabel (val) {
          let label_id = this.loadedLabels[this.editLabelIdx].id;

          if (!val.name || val.name.length < 2) return;
          if (this.willNameDuplicate(val.name, val.bg_color, label_id)) {
            ScNotification.growlErrMsg('Your changes will create a duplicate label');
            return;
          }
          this.addingLabel = true;

          $.ajax({
            method: 'POST', url: '/main/presentations/ajax_update_label',
            data: {
              workspace_id: this.workspaceId,
              label_id:  label_id,
              label_name: val.name,
              label_color: val.bg_color
            }

          }).done(() => {
            ScNotification.growlSuccessMsg('Label has been updated.');

            this.$store.commit('plistLabelsUpdated',
                {'label_id': label_id, 'label_name': val.name, 'label_color': val.bg_color});

            this.showEditLabelBlock = false;
            this.editLabelIdx = null;

          }).always(() => {
            this.addingLabel = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'updating label');
          });
        },

        deleteLabel (label) {
          ScConfirmModal2.modal('Are you sure you want to delete label "' + label.name + '" ?').then(() => {
            //console.log('delete', label);
            $.ajax({
              method: 'POST', url: '/main/presentations/ajax_delete_label',
              data: { workspace_id: this.workspaceId, label_id:  label.id}
            }).done(() => {
              this.showEditLabelBlock = false;
              ScNotification.growlSuccessMsg('Label has been deleted.');
              this.$store.commit('plistLabelDeleted', label.id);
            }).fail((jqXhr) => {
              ScNotification.growlXhrError(jqXhr, 'deleting label');

            });
          }, () => {});
        },

        sortLabel (label, prevLabel) {
          this.updateLabelSorting({ 'sort_with_label': prevLabel, 'label': label });
        },

        updateLabelSorting (labelData) {
          $.ajax({
            method: 'POST', url: '/main/presentations/ajax_update_label',
            data: {
              workspace_id: this.workspaceId,
              label_id: labelData.label.id,
              sort_with_label_id: labelData.sort_with_label.id,
            }
          }).done(() => {
            ScNotification.growlSuccessMsg('Label sorting has been updated.');

            this.$store.commit('plistLabelsSortingUpdated',
                {'label': labelData.label, 'sort_with_label': labelData.sort_with_label });

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'updating');
          });
        },

        willNameDuplicate (name, bgColor, labelIdx) {
          let numMatchingNames = 0;
          this.$store.state.plist.labels.forEach(function(label) {
            if (label.id !== labelIdx && label.name.toLowerCase() === name.toLowerCase() && label.bg_color === bgColor) {
              numMatchingNames++;
            }
          });
          return numMatchingNames > 0;
        },
        toggleBsModal() {
          this.isModalShown = true;
          this.editLabelIdx = null;
        }
      },
      computed: {
        loadedLabels () {
          return this.$store.state.plist.labels;
        },
        maxNumLabels () {
          return constants.MAX_NUM_LABELS;
        },
        tooManyLabels () {
          return this.$store.state.plist.labels.length >= this.maxNumLabels;
        }
      },

      //
      // public functions
      //
      modal() {
        _ins.toggleBsModal()
      }

    }
</script>
