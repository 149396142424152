<template>
  <ScNormalPageLayout :headingPart1="presentationName" headingPart2="Outline">

    <div class="container my-3">
      <div class="row">
        <div v-if="showSpinner" class="text-center my-5">
          <ScIcon name="spinnerMedium" class="my-5 text-muted"/>
        </div>

        <div v-else class="col-12">
          <div class="mt-4 mb-3 px-5 pb-4 border-bottom">
            <OutlineSummary :scStats="scStats"
                            :scData="scData"
                            :scUserPresStats="scUserPresStats"
                            @show-detail-view="showDetailView"></OutlineSummary>
          </div>

          <div v-for="page in sortedSlides"
               class="row pb-3 mt-2 border-bottom"
               :data-page-id="page.id" :data-page-did="page.alpha_num_name" :key="page.id">

            <div class="col-4 text-center">
              <a :name="`slide-id/${page.id}`"></a>
              <div class="mt-4">
                <span class="shadow-sm rounded bg-white thumbnail-page d-inline-block">
                  <img :src="thumbUrl(page.resource_pagethumb_id)" alt="Thumbnail"
                       :title="formatImgTitle(page)"
                       :style="thumbStyleObj" class="m-2"/></span>
              </div>
            </div>

            <div class="col-8">
              <OutlineDetails :slideDetails="page"
                              :scData="scData"
                              :presentationId="presentationId"
                              @show-detail-view="showDetailView"></OutlineDetails>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- for sub routes -->
    <router-view></router-view>

  </ScNormalPageLayout>
</template>

<script>

    import $ from 'jquery';
    import _ from 'underscore'; // isEmpty, indexOf, sortBy, where, each, findWhere, has, isUndefined
    import ScNormalPageLayout from '../../global/ScNormalPageLayout.vue';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import ScIcon from '../../../shared/common/ScIcon.vue';
    import OutlineDetails from './OutlineDetails.vue';
    import OutlineSummary from './OutlineSummary.vue';
    import MainAppMixin from '../../MainAppMixin';
    import PresentationUtils from '../../../store/PresentationUtils';


    export default {
      name: "Outline",
      mixins: [ MainAppMixin ],
      components: { ScNormalPageLayout, OutlineDetails, OutlineSummary, ScIcon },
      data () {
        return {
          workspaceId: null,
          presentationName: null,
          presentationId: 0,
          scUserPresStats: {page_likes: [], resource_likes: [], page_tags: []},
          scStats: {},
          scData: {},
          slideDetails: {},
          isStatsLoaded: false,
          showSpinner: false,
        };
      },
      mounted () {
        this.presentationId = Number(this.$route.params.showcaseId);
        this.workspaceId = this.mainMxCurrentWorkshopId;

        this.getUserPresentationStats(this);
        this.getShowcaseStats(this);
        this.getShowcaseSlides(this);
      },
      methods: {
        getNavigatablePagelistIds () {
          if (_.isEmpty(this.scData))
            return;
          let walkTree = (pagelistId) => {
            if (_.indexOf(walkedPagelistIds, pagelistId) > -1)
              return;  // we already did this one
            walkedPagelistIds.push(pagelistId);
            let pages = _.sortBy(_.where(this.scData.page, {pagelist_id: pagelistId}), 'sort_order');
            _.each(pages, (page) => {
              let hotspots = _.sortBy(_.where(this.scData.hotspot, {parent_page_id: page.id}), 'id');
              _.each(hotspots, (hotspot) => {
                if (hotspot.target_page_id) {
                  let targetPage = _.findWhere(this.scData.page, {id: hotspot.target_page_id});
                  if (targetPage)
                    walkTree(targetPage.pagelist_id);
                }
              });
            });
          };
          let walkedPagelistIds = [];
          walkTree(this.scData.presentationmetadata.root_pagelist_id);
          return walkedPagelistIds;
        },
        getPrevNextPage (page, slides) {
          let prev = false,
              next = false,
              prevSlide = null,
              nextSlide = null;
          for (let i in slides) {
            if (slides[i]){
              if (slides[i].id === page.id) {
                let sortOrder = slides[i].sort_order;

                if (sortOrder > 0) {
                  let slideP = Number(i);
                  slideP--;

                  let prevSortOrder = slides[slideP].sort_order;
                  if (prevSortOrder > -1) {
                    prev = true;
                    prevSlide = slides[slideP];
                  }
                }

                let slideN = Number(i);
                slideN++;

                let nextSortOrder = 0;
                if (slides[slideN] !== undefined)
                  nextSortOrder = slides[slideN].sort_order;

                if (nextSortOrder !== 0) {
                  next = true;
                  nextSlide = slides[slideN];
                }

                return {
                  'has_prev_slide': prev,
                  'has_next_slide': next,
                  'prev_slide': prevSlide,
                  'next_slide': nextSlide
                };
              }
            }
          }
        },
        getIncomingLinkPages (page) {
          let incomingLinkPages = [],
              allSpots = this.scData.hotspot;

          for (let hotspot in allSpots) {
            if (allSpots[hotspot] && allSpots[hotspot].target_page_id === page.id) {
              incomingLinkPages.push(this.scData.page[allSpots[hotspot].parent_page_id]);
            }
          }

          return {
            'incoming_link_pages': incomingLinkPages,
            'has_incoming_link_page': incomingLinkPages.length > 0
          };
        },
        getIntroVideo (page) {
          let scPresentationMeta = this.scData.presentationmetadata,
              introVideoRes = null,
              hasIntroVideo = false;

          if (scPresentationMeta.root_pagelist_id === page.pagelist_id
              && page.prev_slide === null
              && scPresentationMeta.opening_scene_resource_id) {
            hasIntroVideo = true;
            introVideoRes = this.scData.resource[scPresentationMeta.opening_scene_resource_id];
          }
          return {
            'intro_video_res': introVideoRes,
            'has_intro_video': hasIntroVideo
          };
        },
        linkedHotspots (page) {
          let hotspots = [];
          _.each(this.scData.hotspot, (spot) => {
            if (this.checkHotspotHasLink(spot, page.id))
              hotspots.push(spot);
          });
          return hotspots;
        },
        checkHotspotHasLink (hotspot, pageId) {
          return (
              _.has(hotspot, 'parent_page_id') && hotspot.parent_page_id === pageId
              && (
                  (_.has(hotspot, 'target_resource_id') && hotspot.target_resource_id)
                  || (_.has(hotspot, 'target_pagelist_id') && hotspot.target_pagelist_id)
              )
          );
        },
        showDetailView (pageId) {
          if (!pageId) return;
          this.$nextTick(() => {
            let value = `[data-page-id="${pageId}"]`;
            document.querySelectorAll(value)[0].scrollIntoView({block: "center", behavior: "smooth"});
          });
        },
        getShareableItems (slide) {
          let shareableOnSlide = [];
          for (let i in this.scData.shareable_page_resource) {
            if (slide.id === this.scData.shareable_page_resource[i].page_id) {
              for (let e in this.scData.resource) {
                if (this.scData.shareable_page_resource[i].resource_id === this.scData.resource[e].id)
                  shareableOnSlide.push(this.scData.resource[e]);
              }
            }
          }
          return slide.shareableOnSlide = shareableOnSlide;
        },
        getUserPresentationStats() {
          $.ajax({
            url: '/main/user_presentation/ajax_user_pres_stats',
            data: {presentation_id: this.presentationId, workspace_id: this.workspaceId}
          }).done((data) => {
            this.scUserPresStats = data;
            //console.log('scUserPresStats', vm.scUserPresStats);
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading presentation user statistics');
          });
        },
        getShowcaseStats() {
          this.scStats = {};
          $.ajax({
            url: '/main/presentations/ajax_showcase_stats',
            data: {workspace_id: this.workspaceId, presentation_id: this.presentationId}
          }).done((data) => {
            this.scStats = data.stats;
            this.isStatsLoaded = true;
            //console.log('STATS', this.scStats);
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading statistics');
          })
        },
        getShowcaseSlides() {
          this.scData = {};
          this.presentationName = null;
          this.showSpinner = true;
          $.ajax({
            url: '/main/editor/ajax_draft_showcase',
            data: {workspace_id: this.workspaceId, presentation_id: this.presentationId}
          }).done((data) => {
            //console.log('SLIDES', data);
            this.presentationName = data.export.presentation.title;
            this.scData = data.export;
          }).always(() => {
            this.showSpinner = false;
          }).fail(function (jqXhr) {
            ScNotification.growlXhrError(jqXhr, 'loading presentation');
          })
        },
        thumbUrl (id) {
          let res = this.scData.resource[id];
          return res ? res.thumb_url : null;
        },
        formatImgTitle (page) {
          let imgBgTitle = (!page.background_src_resource_id) ? "" : this.scData.resource[page.background_src_resource_id].name;
          return `page.alpha_num_name ${imgBgTitle}`;
        },
      },
      computed: {
        thumbStyleObj () {
          return {
            'width': '100%',
            'max-width': this.scData.primary_layout.thumb_width + 'px'
          };
        },
        sortedSlides () {
          let sortedSlides = [];
          let navigatable = this.getNavigatablePagelistIds();
          if (!this.scData.pagelist) return [];
          Object.keys(this.scData.pagelist).forEach((plId) => {
            let sortedPages = _.where(this.scData.page, {pagelist_id: Number(plId)});
            sortedPages = _.sortBy(sortedPages, 'sort_order');

            for (let page of sortedPages) {
              if (navigatable.includes(page.pagelist_id)) {
                sortedSlides.push(page);
              }
            }
          });

          sortedSlides.sort(PresentationUtils.pageAlphaNumSortComparator);
          for (let page in sortedSlides) {
            let _stateSlide = sortedSlides[page];

            let prevNextSlide = this.getPrevNextPage(sortedSlides[page], sortedSlides);
            _stateSlide.has_prev_slide = prevNextSlide.has_prev_slide;
            _stateSlide.has_next_slide = prevNextSlide.has_next_slide;
            _stateSlide.prev_slide = prevNextSlide.prev_slide;
            _stateSlide.next_slide = prevNextSlide.next_slide;

            let incLP = this.getIncomingLinkPages(sortedSlides[page]);
            _stateSlide.incoming_link_pages = incLP.incoming_link_pages;
            _stateSlide.has_incoming_link_page = incLP.has_incoming_link_page;

            let introVideo = this.getIntroVideo(sortedSlides[page]);
            _stateSlide.has_intro_video = introVideo.has_intro_video;
            _stateSlide.intro_video_res = introVideo.intro_video_res;

            let linkedHotspots = this.linkedHotspots(sortedSlides[page]);
            if (linkedHotspots && linkedHotspots.length > 0) {
              _stateSlide.has_hotspots = true;
              _stateSlide.linked_hotspots = linkedHotspots;
            }
            this.getShareableItems(_stateSlide);
          }
          return sortedSlides;
        },


      },
      watch: {
        scData (newV) {
          if (Object.keys(newV).length > 0 && this.$route.query.pid) {
            for (let slide in newV.page) {
              if (!_.isUndefined(slide) && slide === this.$route.query.pid){
                this.slideDetails = newV.page[slide];
              }
            }
          }
        }
      }

    }
</script>

