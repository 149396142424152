<template>
    <div class="mb-4 pb-4 border-bottom">
        <h3>
            <button v-if="showMore" class="btn btn-link float-end fw-bold"  @click.prevent="showMore=false">
                Show less <ScIcon name="angleUp" class="ms-1"></ScIcon></button>
            <button v-if="!showMore" @click.prevent="showMore=true" class="btn btn-link float-end fw-bold">
                Show more <ScIcon name="angleDown" class="ms-1"></ScIcon></button>
            Blank
        </h3>

        <div class="mt-3">
            <div :class="['d-flex align-items-center', showMore ? 'flex-wrap' : 'flex-nowrap']"
                 :style="{'overflow-x': 'auto'}">
                <div v-for="(item, idx) in loadedData" :key="'img-' + idx"
                     class="pres-blank-template-preview text-center">
<!--                    <span v-if="item.isPopular" class="badge bg-info">-->
<!--                      Most popular-->
<!--                    </span>-->
                    <button type="button" class="btn btn-link pb-0 mb-0" @click.prevent="createPres(item.code)">
                        <span v-if="item.isPopular" class="badge rounded-pill bg-info py-1 px-2">
                          Most Popular</span><br/>
                        <PresThumbWithChassis :pres="item" :thumbScale="0.8"></PresThumbWithChassis>
                        <br><strong>{{ item.title }}</strong>
                    </button>
                    <br/><small class="text-muted">{{ item.width }} x {{ item.height }} px</small>
                </div>
            </div>
        </div>
        <div v-if="isLoading" class="text-center pt-5">
            <ScIcon name="spinnerFW" class="text-muted"/>
        </div>
        <div class="mt-3 ms-2 text-muted">
            <small>
                Any aspect ratio will work on any device. Letterboxing may occur if the screen size is not an exact
                match.

                <br/>You can design slide backgrounds in separate software (InDesign, Photoshop, etc); export slides as image
                    files, then use Showcase to link slides, add PDFs and videos, share and track usage.

                <br/>Using InDesign? Try our IDML Templates:
                <a href="https://cdn.showcaseworkshop.com/showcase-templates/1.0.0/Showcase-16x9-Template-HD.idml"
                   target="_blank"><ScIcon name="download" aria-hidden="true"/> 16:9 Landscape</a>
                | <a href="https://cdn.showcaseworkshop.com/showcase-templates/1.0.0/Showcase-Retina-iPad-Template.idml"
                     target="_blank"><ScIcon name="download" aria-hidden="true"/> 4:3 Landscape</a>
                | <a href="https://cdn.showcaseworkshop.com/showcase-templates/2.0.0/Showcase-4x3-Template-Portrait.idml"
                     target="_blank"><ScIcon name="download" aria-hidden="true"/> 4:3 Portrait</a>
                | <a href="https://cdn.showcaseworkshop.com/showcase-templates/2.0.0/Showcase-3x2-Template-Landscape.idml"
                     target="_blank"><ScIcon name="download" aria-hidden="true"/> 3:2 Landscape</a>
                | <a href="https://cdn.showcaseworkshop.com/showcase-templates/2.0.0/Showcase-16x9-Template-Portrait.idml"
                     target="_blank"><ScIcon name="download" aria-hidden="true"/> 16:9 Portrait</a>
                | <a href="https://cdn.showcaseworkshop.com/showcase-templates/2.0.0/Showcase-3x2-Template-Portrait.idml"
                     target="_blank"><ScIcon name="download" aria-hidden="true"/> 3:2 Portrait</a>.
            </small>
        </div>
    </div>
</template>

<script>

    import ScIcon from '../../shared/common/ScIcon.vue';
    import MainAppMixin from '../MainAppMixin';
    import PresThumbWithChassis from '../presentation/PresThumbWithChassis.vue';
    import constants from '../../constants';

    export default {
        name: 'NewFromBlank',
        emits: ['create-pres'],
        mixins: [MainAppMixin],
        components: {ScIcon, PresThumbWithChassis},
        data () {
            return {
                showMore: false,
                isLoading: false,
                layouts: constants.ALL_PRIMARY_LAYOUTS,
            };
        },

        methods: {
            createPres(layoutCode) {
                this.$emit('create-pres', layoutCode);
            },
        },
        computed: {
            selectedLayout () {
                return this.layouts[this.orientation + '-' + this.aspectRatio + '-' + (this.templateIsHd?'hd':'sd')];
            },
            loadedData() {
                return this.layouts.map((layout) => {
                    layout.thumbnail = 'https://cdn.showcaseworkshop.com/showcase-icons/5.0.0/black_square_160px.png';
                    return layout;
                })
            }
        },
    }

</script>

<style>

.pres-blank-template-preview:hover button img {
    border: 1px solid white;
}

</style>
<style scoped>


</style>
