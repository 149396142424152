<template>
  <div class="ms-3">
    <h3>Summary</h3>
    <div v-if="likesSummaryText" class="text-muted row">
      <div class="col-auto mb-3">
        <button type="button" @click.prevent="showOutlineLikesModal" class="btn btn-link ps-0">
          <ScIcon name="heartFW" class="me-1"/>
          {{ likesSummaryText }}
          <ScIcon name="hashtagFW" class="ms-3 me-1"/>
          {{ tagsCount }} tagged
        </button>
        <OutlineLikesModal></OutlineLikesModal>
      </div>
    </div>
    <div v-if="scStats" class="text-muted">
      <span v-if='scStats.total_bytesize_hd === 0'>total size 0 bytes</span>
      <span v-else>
        <strong>{{ formatSlideCount }},
          {{formatHotspotCount}},
          total size {{ formatToFileSizeWithBytes(scStats.total_bytesize_hd) }}</strong>
        (<template v-if="hasImageBytes">images {{ formatToFileSizeWithBytes(scStats.images_bytesize) }}</template>
        <template v-if="hasVideoBytes"><span v-if="hasImageBytes">, </span>videos {{ formatToFileSizeWithBytes(scStats.movies_bytesize_hd) }}</template>
        <template v-if="hasDocumentBytes"><span v-if="hasImageBytes || hasVideoBytes">, </span>documents {{ formatToFileSizeWithBytes(scStats.documents_bytesize) }}</template>)
        <br/>
        Size for <span v-sc-tooltip="'Screen width/height of less than 1025px'">small devices</span>
        {{ formatToFileSizeWithBytes(scStats.total_bytesize_sd) }}
        <template v-if="hasVideoBytes" >
          (videos {{ formatToFileSizeWithBytes(scStats.movies_bytesize_sd) }})
        </template>
      </span>
    </div>
    <div class="mt-3" v-if="shareableOnAll.length > 0 || showcaseShareable">
      <div class="text-muted">
        <strong>Shareable on all Slides</strong>
        <ScIcon name="questionCircleFW" v-sc-tooltip="'To change this, click Manage Sharing from Editor or Home'" class="ms-1"/>
      </div>
      <div v-if="showcaseShareable"><ScIcon name="linkFW"/> Full presentation access</div>
      <div v-for="item in sortShareableOnAll" :key="'ShareableItem' + item.id">
        <ScIcon :name="shareableIconName(item)" class="me-1"/>
        {{ item.name }}
        <span class="mx-1 text-muted">{{formatToFileSizeWithBytes(getResByteSize(item))}}</span>
        <a href="#" v-sc-trackevent="'outline:resource_info_popover'" class="hidden-print resource-info-popover-link"
           @click.prevent="mainMxShowResInfo(item.id)"><ScIcon name="infoCircle"/></a>
      </div>
    </div>
    <div class="mt-3 text-muted" v-if="screensaverVideoResource && hasScreensaverVideo">
      <div>
        <strong>Screensaver video</strong>
        (starts after {{ screensaverTimeout ? screensaverTimeout : 1 }} {{screensaverTimeout && screensaverTimeout > 1 ? 'minutes' : 'minute'}})
        <ScIcon name="questionCircleFW" v-sc-tooltip="'This is the video that will play when the presentation is left unattended. To change this, click the Screensaver button in the Editor.'"
                class="ms-1"/>
        <br/>
        <ScIcon name="fileVideoFW" class="me-1"/>
        {{ screensaverVideoResource.name }}
        <span class="ms-1 text-muted me-1">{{formatToFileSizeWithBytes(screensaverVideoResource.bytesize)}}</span>
        <a href="#" v-sc-trackevent="'outline:resource_info_popover'" class="hidden-print resource-info-popover-link"
           @click.prevent="mainMxShowResInfo(screensaverVideoResource.id)"><ScIcon name="infoCircle"/></a>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>

  import constants from '../../../constants';
  import OutlineLikesModal from './OutlineLikesModal.vue';
  import MainAppMixin from '../../MainAppMixin';
  import ScIcon from '../../../shared/common/ScIcon.vue';
  import ScCommonUtil from '../../../shared/common/ScCommonUtil';
  import OutlineUtil from "./OutlineUtil";

  export default {
    name: 'OutlineSummary',
    emits: ['show-detail-view'],
    mixins: [MainAppMixin],
    components: {ScIcon, OutlineLikesModal},
    props: {
      scStats: {type: Object, default: null},
      scData: {type: Object, default: null},
      scUserPresStats: {type: Object, default: null}
    },
    data () {
      return {
        //
      }
    },
    methods: {
      shareableIconName (item) {
        return constants.RES_CONTENT_TYPE_TO_ICON[item.content_type];
      },
      showOutlineLikesModal () {
        OutlineLikesModal.modal(this.scUserPresStats, this.scData, (e) => {
          this.$emit('show-detail-view', e.id);
        });
      },
      getResByteSize (item) {
        return OutlineUtil.getResByteSize(item, this.scData);
      },
      formatToFileSizeWithBytes (val) {
        return (val > 0) ? ScCommonUtil.filesizeWithBytes(val) : 0;
      },
    },
    computed: {
      shareableOnAll () {
        let shareable = [];
        if (Object.keys(this.scData).length === 0 && this.scData.constructor === Object)
          return shareable;

        for (let i in this.scData.shareable_resource) {
          for (let e in this.scData.resource) {
            if (this.scData.shareable_resource[i].resource_id === this.scData.resource[e].id &&
                this.scData.resource[e].content_type !== 'showcase_share')
              shareable.push(this.scData.resource[e])
          }
        }
        return shareable;
      },
      showcaseShareable() {
        if (Object.keys(this.scData).length === 0 && this.scData.constructor === Object)
          return false;
        return !!Object.values(this.scData.resource).find(res => res.content_type === 'showcase_share')
      },
      sortShareableOnAll () {
        let sorted = this.shareableOnAll;
        return sorted.sort((a, b) => {
          let nameA = a.name.toLowerCase();
          let nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      },
      hasImageBytes () {
        return (this.scStats.images_bytesize && Number(this.scStats.images_bytesize) > 0);
      },
      hasVideoBytes () {
        return (this.scStats.movies_bytesize_hd && Number(this.scStats.movies_bytesize_hd) > 0);
      },
      hasDocumentBytes () {
        return (this.scStats.documents_bytesize && Number(this.scStats.documents_bytesize) > 0);
      },
      likesCount () {
        let numLikes = 0;
        this.scUserPresStats.page_likes.forEach((summary) => {
          numLikes += summary.likes_count;
        });
        this.scUserPresStats.resource_likes.forEach((summary) => {
          numLikes += summary.likes_count;
        });
        return numLikes;
      },
      tagsCount () {
        let numTags = 0;
        this.scUserPresStats.page_tags.forEach((summary) => {
          numTags += summary.tag_count;
        });
        return numTags;
      },
      likesSummaryText () {
        return `${this.likesCount} favorite${(this.likesCount > 1 || this.likesCount === 0) ? 's' : ''}`;
      },
      formatSlideCount () {
        return `${this.scStats.total_num_pages} slide${(this.scStats.total_num_pages > 1 || this.scStats.total_num_pages === 0) ? 's' : ''}`;
      },
      formatHotspotCount () {
        return `${this.scStats.total_num_hotspots} hotspot${(this.scStats.total_num_hotspots > 1 || this.scStats.total_num_hotspots === 0) ? 's' : ''}`;
      },
      hasScreensaverVideo () {
        return !!(this.scData.presentationmetadata && this.scData.presentationmetadata.screensaver_video_resource_id)
      },
      screensaverVideoResource () {
        return (this.hasScreensaverVideo) ?
            this.scData.resource[this.scData.presentationmetadata.screensaver_video_resource_id] : null;
      },
      screensaverTimeout () {
        return (this.hasScreensaverVideo) ? this.scData.presentationmetadata.screensaver_timeout : null;
      }
    }
  }
</script>
